import { Button, Dropdown, Space, message, Alert } from "antd"
import { Link } from "gatsby"
import React from "react"
import * as style from "./style.module.scss"
import { SVGDiscord, SVGReddit, SVGTelegram, SVGTwitter, SVGAdaHandle } from "@/svg"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SettingsActions } from "@/redux/settings"
import { numberFromString } from "@/utils/utils"
import { SVGCardano } from "@/svg"
import Jazzicon from "react-jazzicon"

const Header = () => {
  const dispatch = useAppDispatch()
  const networkOnline = useAppSelector((state) => state.network.online)
  const settingsTheme = useAppSelector((state) => state.settings.theme)
  const adaHandle = useAppSelector((state) => state.settings.adaHandle)
  const adaHandleDisabled = useAppSelector((state) => state.settings.adaHandleDisabled)
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const balanceLoading = useAppSelector((state) => state.account.balanceLoading)
  const pendingTxs = useAppSelector((state) => state.account.pendingTxs)

  const isAdaHandleEnabled = !adaHandleDisabled[accountCurrent?.id || ""] && adaHandle?.[accountCurrent?.id || ""]
  const txPending = !!pendingTxs?.[accountCurrent?.id || ""] && !pendingTxs?.[accountCurrent?.id || ""]?.sent

  return (
    <div>
      <div className={style.header}>
        <div className={style.menu}>
          <div className={style.logo}>
            <Link to="/" className={style.logo}>
              RayStake
            </Link>
          </div>
          <div className={style.links}>
            <Link to="/tokens-staking/" className={style.link} activeClassName={style.linkActive} partiallyActive>
              Tokens
            </Link>
            <Link to="/ada-staking/" className={style.link} activeClassName={style.linkActive} partiallyActive>
              ADA
            </Link>
            <Link to="/calculator/" className={style.link} activeClassName={style.linkActive} partiallyActive>
              Calculator
            </Link>
          </div>
        </div>
        <div className={style.extra}>
          <Dropdown
            arrow
            className={style.more}
            placement="bottomRight"
            trigger={["click"]}
            dropdownRender={() => (
              <div className="ray-dropdown">
                <div className={style.linksMobile}>
                  <Link
                    to="/tokens-staking/"
                    className="ray-dropdown-item ray-dropdown-item--button"
                    activeClassName={style.linkActive}
                    partiallyActive
                  >
                    <strong>Tokens Staking</strong>
                    <div className="ms-auto">
                      <i className="ri ri-category" />
                    </div>
                  </Link>
                  <Link
                    to="/ada-staking/"
                    className="ray-dropdown-item ray-dropdown-item--button"
                    activeClassName={style.linkActive}
                    partiallyActive
                  >
                    <strong>ADA Staking</strong>
                    <div className="ms-auto">
                      <SVGCardano className={style.cardano} />
                    </div>
                  </Link>
                  <Link
                    to="/calculator/"
                    className="ray-dropdown-item ray-dropdown-item--button"
                    activeClassName={style.linkActive}
                    partiallyActive
                  >
                    <strong>Calculator</strong>
                    <div className="ms-auto">
                      <i className="ri ri-calculator" />
                    </div>
                  </Link>
                  <div className="ray-dropdown-divider" />
                </div>
                {/* <Link
                  to="/faq/"
                  className="ray-dropdown-item ray-dropdown-item--button"
                  activeClassName={style.linkActive}
                  partiallyActive
                >
                  <strong>FAQ</strong>
                  <div className="ms-auto">
                    <i className="ri ri-info" />
                  </div>
                </Link> */}
                {/* <div className="ray-dropdown-divider" /> */}
                <div
                  className="ray-dropdown-item ray-dropdown-item--button"
                  onClick={() =>
                    dispatch(SettingsActions.THEME_CHANGE_SAGA(settingsTheme === "default" ? "dark" : "default"))
                  }
                >
                  {settingsTheme !== "default" && (
                    <>
                      <strong>Dark Theme</strong>
                      <div className="ms-auto">
                        <i className="ri ri-moon" />
                      </div>
                    </>
                  )}
                  {settingsTheme === "default" && (
                    <>
                      <strong>Light Theme</strong>
                      <div className="ms-auto">
                        <i className="ri ri-sun" />
                      </div>
                    </>
                  )}
                </div>
                <div className="ray-dropdown-divider" />
                <div className="ray-dropdown-item p-0">
                  <div className={style.socials}>
                    <a href="https://twitter.com/RayNetwork" target="_blank" rel="noopener noreferrer">
                      <SVGTwitter />
                    </a>
                    <a href="https://discord.gg/dDVXcthYWn" target="_blank" rel="noopener noreferrer">
                      <SVGDiscord />
                    </a>
                    <a href="https://t.me/RayNetwork" target="_blank" rel="noopener noreferrer">
                      <SVGTelegram />
                    </a>
                    <a href="https://www.reddit.com/r/RayNetwork" target="_blank" rel="noopener noreferrer">
                      <SVGReddit />
                    </a>
                  </div>
                </div>
              </div>
            )}
          >
            <Space>
              <span>
                <i />
                <i />
                <i />
              </span>
            </Space>
          </Dropdown>
        </div>
        <div>
          <div>
            {!accountCurrent && (
              <Button
                size="large"
                className="ray-btn ray-btn-dark"
                onClick={() => dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: true, type: "connect" }))}
              >
                <i className="ri ri-add" />
                <span className="d-none d-sm-inline ms-2">Connect</span>
              </Button>
            )}
          </div>
          <div>
            {accountCurrent && (
              <div className="d-flex">
                <Button
                  size="large"
                  className="ray-btn px-0 rounded-top-right-none rounded-bottom-right-none"
                  onClick={() => dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: true, type: "account" }))}
                >
                  <div className="d-flex align-items-center">
                    <div className={style.avatar}>
                      <div
                        className={`${style.avatarLoading} ${
                          balanceLoading || txPending ? style.avatarLoadingActive : ""
                        }`}
                      >
                        <i className="ray-spinner ray-spinner-md" />
                      </div>
                      {accountCurrent.type === "hw" && <span className="ray-dot ray-dot-blue" />}
                      {accountCurrent.type === "connector" && accountCurrent.online && <span className="ray-dot" />}
                      <Jazzicon diameter={28} seed={numberFromString(accountCurrent.stakeKey)} />
                    </div>
                    <span className="ms-1 me-2 font-size-16">
                      {isAdaHandleEnabled && (
                        <>
                          <span className="ray-handle-icon">
                            <SVGAdaHandle />
                          </span>
                          <span className="ray-handle-title">
                            {adaHandle?.[accountCurrent?.id || ""]?.assetNameAscii}
                          </span>
                        </>
                      )}
                      {!isAdaHandleEnabled && accountCurrent?.checksum}
                    </span>
                  </div>
                </Button>
                <Button
                  size="large"
                  className={`${style.settings} ray-btn px-2 rounded-top-left-none rounded-bottom-left-none`}
                  onClick={() => dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: true, type: "settings" }))}
                >
                  <i className="ri ri-settings me-1" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {!networkOnline && (
          <div className={style.offline}>
            <Alert
              type="warning"
              message="Connection lost!"
              description="Looks like you lost your connection. Please check it and reload the app again."
              showIcon
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
