import React from "react"
import { truncate } from "@/utils/utils"
import CONSTANTS from "@/config/constants"
import { Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"

const InformerLinkCardanoscan = ({
  title,
  link,
  truncate: _truncate,
  type,
}: {
  title: string
  link: string
  truncate?: number
  type: "transaction" | "token" | "block" | "stakeKey"
}) => {
  const mapLinks = CONSTANTS.explorer
  return (
    <span className="ray-link" onClick={(e) => e.stopPropagation()}>
      <CopyToClipboard text={title} onCopy={() => message.success("Copied")}>
        <Tooltip title="Copy">
          <span className="cursor-pointer me-2">{truncate(title, _truncate, 4)}</span>
        </Tooltip>
      </CopyToClipboard>
      <Tooltip title="Open in Explorer">
        <a href={`${mapLinks[type]}${link}`} target="_blank" rel="noopener noreferrer">
          <i className="ri ri-compass font-size-14" />
        </a>
      </Tooltip>
    </span>
  )
}

export default InformerLinkCardanoscan
