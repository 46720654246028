import { bech32 } from "bech32"
import { Buffer } from "buffer"

declare global {
  interface Window {
    cardano: any
  }
}

export const getConnectors: () => string[] = () => {
  return Object.keys(window?.cardano || {})
    .map((key) => (window?.cardano?.[key]?.apiVersion ? key : ""))
    .filter((item) => item !== "")
}

export const getConnectorsWithStatus: any = async () => {
  const connectors = getConnectors()
  const connectorsWithStatus = await Promise.all(
    connectors.map(async (id) => {
      const isEnabled = await window?.cardano?.[id].isEnabled()
      let stakeKey = null
      try {
        stakeKey = isEnabled
          ? await window?.cardano?.[id].enable().then(async (instance: any) => {
              return await instance.getRewardAddresses().then((stakeKeys: any) => {
                return stakeKeys[0]
              })
            })
          : null
      } catch {
        console.log("getConnectorsWithStatus :: can't get connectors statuses")
      }
      return {
        connectorId: id,
        enabled: isEnabled,
        stakeKey: stakeKey && bech32.encode("stake", bech32.toWords(Buffer.from(stakeKey, "hex"))),
      }
    })
  )
  return connectorsWithStatus
}

export const getChangeAddress = async (connector: string) => {
  try {
    if (window?.cardano?.[connector]) {
      return await window?.cardano?.[connector]?.getChangeAddress()
    }
    return null
  } catch {
    return null
  }
}

export const signTx = async (connectorId: string, tx: string) => {
  // try {
  const isEnabled = await window?.cardano?.[connectorId].isEnabled()
  if (window?.cardano?.[connectorId] && isEnabled) {
    return await window?.cardano?.[connectorId].enable().then(async (instance: any) => {
      return await instance.signTx(tx).then((tx: any) => {
        return tx
      })
    })
  }
  return null
  // } catch {
  // return null
  // }
}

export const submitTx = async (connectorId: string, tx: string) => {
  // try {
  const isEnabled = await window?.cardano?.[connectorId].isEnabled()
  if (window?.cardano?.[connectorId] && isEnabled) {
    return await window?.cardano?.[connectorId].enable().then(async (instance: any) => {
      return await instance.submitTx(tx).then((hash: any) => {
        return hash
      })
    })
  }
  return null
  // } catch {
  // return null
  // }
}
