import { useEffect } from "react"
import { RootState, useAppSelector, useAppDispatch } from "@/redux/provider"
import { NetworkActions, NetworkTypes } from "@/redux/network"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { AccountActions, AccountTypes } from "@/redux/account"
import { StakingAdaActions, StakingAdaTypes } from "@/redux/stakingAda"
import config from "@/config/config"

const Runner = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch()
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const balanceTokens = useAppSelector((state) => state.account.balanceTokens)
  const balanceBootstrapped = useAppSelector((state) => state.account.balanceBootstrapped)
  const modalAdaTransaction = useAppSelector((state) => state.transaction.modalAdaTransaction)
  const pendingTxs = useAppSelector((state) => state.account.pendingTxs)

  const success = !!pendingTxs?.[accountCurrent?.id || ""]?.sent

  useEffect(() => {
    dispatch(SettingsActions.INIT())
    dispatch(NetworkActions.NETWORK_UPDATE_SAGA())
    dispatch(NetworkActions.EXCHANGE_RATES_UPDATE_SAGA())
    dispatch(StakingAdaActions.PREMIUM_POOLS_UPDATE_SAGA())
    dispatch(AccountActions.PENDING_TXS_CHECK_SAGA())

    setInterval(() => {
      dispatch(NetworkActions.NETWORK_UPDATE_SAGA())
      dispatch(NetworkActions.EXCHANGE_RATES_UPDATE_SAGA())
      dispatch(AccountActions.ACCOUNT_INFO_UPDATE_SAGA("silent"))
    }, 30_000)

    setInterval(() => {
      dispatch(SettingsActions.CONNECTORS_UPDATE_STATUS_SAGA())
      dispatch(AccountActions.PENDING_TXS_CHECK_SAGA())
    }, 10_000)
  }, [])

  useEffect(() => {
    if (!navigator.onLine) dispatch(NetworkActions.NETWORK_STATUS_SET(false))
    const handleOnline = () => {
      dispatch(NetworkActions.NETWORK_STATUS_SET(true))
    }
    const handleOffline = () => {
      dispatch(NetworkActions.NETWORK_STATUS_SET(false))
    }
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)
    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  useEffect(() => {
    dispatch(AccountActions.ACCOUNT_INFO_UPDATE_SAGA("default", true))
  }, [accountCurrent?.id])

  useEffect(() => {
    const tokens = balanceTokens.map((token) => {
      return {
        policyId: token.asset.policyId,
        assetName: token.asset.assetName,
        assetNameAscii: token.asset.assetNameAscii,
        fingerprint: token.asset.fingerprint,
      }
    })
    const tokensAdaHandle = tokens.filter((token) => token.policyId === config.adaHandlePolicyId)

    dispatch(NetworkActions.CACHED_TOKENS_UPDATE_SAGA(tokens))
    dispatch(SettingsActions.ADA_HANDLE_UPDATE_SAGA(tokensAdaHandle))
  }, [balanceTokens])

  useEffect(() => {
    if (success) {
      dispatch(AccountActions.PENDING_TX_REMOVE_SAGA())
    }
  }, [modalAdaTransaction.open])

  return children
}

export default Runner
