import React from "react"
import { Skeleton } from "antd"
import * as style from "./style.module.scss"

const LoadingSkeleton = ({
  loading = true,
  title = false,
  rows = 2,
  children,
}: {
  loading: boolean
  title?: boolean
  rows?: number
  children: any
}) => {
  return (
    <div className={`${style.container} ${loading ? style.containerLoading : ""}`}>
      {loading && (
        <div className={style.skeleton}>
          <Skeleton title={title} paragraph={{ rows }} active />
        </div>
      )}
      <div className={style.content}>{children}</div>
    </div>
  )
}

export default LoadingSkeleton
