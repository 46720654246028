import React from "react"
import { Table, Tag, Input } from "antd"
import { dateFormatFull, truncate } from "@/utils/utils"
import { AccountTypes } from "@/redux/account"
import { NetworkTypes } from "@/redux/network"
import Informer from "@/components/Informer"
import type { ColumnsType } from "antd/es/table"

const columns: ColumnsType<{
  asset: AccountTypes.BalanceToken["asset"]
  asset_info: NetworkTypes.AssetInformation | null
  quantity: AccountTypes.BalanceToken["quantity"]
}> = [
  {
    title: "#",
    key: "number",
    width: "30px",
    // sorter: (a, b) => a
    render: (record, records, index) => index + 1,
  },
  {
    title: "Name",
    dataIndex: ["asset", "assetNameAscii"],
    key: "token",
    render: (record, records) => (
      <Informer.TokenData
        fingerprint={records.asset.fingerprint}
        policyId={records.asset.policyId}
        assetName={records.asset.assetName}
        assetNameAscii={record}
        maxWidth={180}
        disableUpdateCache
      />
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "25%",
    render: (record, records) => (
      <Informer.TokenInline
        amount={record}
        decimals={records.asset_info?.token_registry_metadata?.decimals || 0}
        small
      />
    ),
  },
  {
    title: "Fingerprint",
    dataIndex: ["asset", "fingerprint"],
    key: "fingerprint",
    width: "25%",
    align: "right",
    render: (record) => <Informer.LinkCardanoscan title={record} truncate={4} link={record} type="token" />,
  },
]

const AccountTokens = ({
  data,
  loading,
  border,
  wide = true,
  size = "middle",
  pageSize = 10,
}: {
  data: {
    asset: AccountTypes.BalanceToken["asset"]
    asset_info: NetworkTypes.AssetInformation | null
    quantity: AccountTypes.BalanceToken["quantity"]
  }[]
  loading?: boolean
  border?: boolean
  wide?: boolean
  size?: "small" | "middle" | "large"
  pageSize?: number
}) => {
  return (
    <div className={`ray-table ${wide ? "ray-table-wide" : ""} ${border ? "ray-table-border" : ""}`}>
      <Table
        rowKey={(i) => i.asset.fingerprint}
        dataSource={data}
        columns={columns}
        size={size}
        pagination={{
          position: ["bottomLeft"],
          size: "default",
          pageSize,
          showSizeChanger: false,
          showQuickJumper: false,
          showPrevNextJumpers: false,
        }}
        loading={{
          spinning: !!loading,
          indicator: <i className="ray-spinner" />,
        }}
        locale={{
          emptyText: <div className="py-4 mb-1">No Data</div>,
        }}
      />
    </div>
  )
}

export default AccountTokens
