// extracted by mini-css-extract-plugin
export var avatar = "style-module--avatar--218f4";
export var avatarLoading = "style-module--avatarLoading--01dc1";
export var avatarLoadingActive = "style-module--avatarLoadingActive--59387";
export var cardano = "style-module--cardano--92c96";
export var extra = "style-module--extra--22cb6";
export var header = "style-module--header--692ed";
export var link = "style-module--link--76c32";
export var linkActive = "style-module--linkActive--ecd76";
export var links = "style-module--links--f50f5";
export var linksMobile = "style-module--linksMobile--99eb6";
export var logo = "style-module--logo--329d4";
export var menu = "style-module--menu--a4b91";
export var more = "style-module--more--6f4c6";
export var offline = "style-module--offline--3b0ce";
export var settings = "style-module--settings--404f4";
export var socials = "style-module--socials--238e5";