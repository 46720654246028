import Loader from "./loader"

const protocolParameters = {
  linearFee: {
    minFeeA: "44",
    minFeeB: "155381",
  },
  minUtxo: "1000000", //p.min_utxo, minUTxOValue protocol paramter has been removed since Alonzo HF. Calulation of minADA works differently now, but 1 minADA still sufficient for now
  poolDeposit: "500000000",
  keyDeposit: "2000000",
  coinsPerUtxoWord: "4310",
  maxValSize: "5000",
  priceMem: 0.0577,
  priceStep: 0.0000721,
  maxTxSize: 16384,
  collateralPercentage: 150,
  maxCollateralInputs: 3,
  ttl: 1800,
  weights: Uint32Array.from([
    200, // weight ideal > 100 inputs
    1000, // weight ideal < 100 inputs
    1500, // weight assets if plutus
    800, // weight assets if not plutus
    800, // weight distance if not plutus
    5000, // weight utxos
  ]),
}

export const assetsToValue = async (value, tokens = []) => {
  await Loader.load()

  const cardanoValue = Loader.Cardano.Value.new(Loader.Cardano.BigNum.from_str(value))
  if (tokens && tokens.length === 0) {
    return cardanoValue
  }
  const assets = Loader.Cardano.MultiAsset.new()
  tokens.forEach((token) => {
    const policyId = Loader.Cardano.ScriptHash.from_bytes(Buffer.from(token.asset.policyId, "hex"))
    const assetName = Loader.Cardano.AssetName.new(Buffer.from(token.asset.assetName || "", "hex"))
    const quantity = Loader.Cardano.BigNum.from_str(token.quantity)
    const asset = assets.get(policyId) ?? Loader.Cardano.Assets.new()
    asset.insert(assetName, quantity)
    assets.insert(policyId, asset)
  })
  if (assets.len() > 0) {
    cardanoValue.set_multiasset(assets)
  }
  return cardanoValue
}

export const utxoFromJson = async (utxos) => {
  await Loader.load()

  const _utxos = await Promise.all(
    utxos.map(async (output) => {
      return Loader.Cardano.TransactionUnspentOutput.new(
        Loader.Cardano.TransactionInput.new(
          Loader.Cardano.TransactionHash.from_bytes(Buffer.from(output.transaction.hash, "hex")),
          Loader.Cardano.BigNum.from_str(output.index.toString())
        ),
        Loader.Cardano.TransactionOutput.new(
          Loader.Cardano.Address.from_bech32(output.address),
          await assetsToValue(output.value, output.tokens)
        )
      )
    })
  )
  return _utxos
}

export const addWitnessToTx = async (witness, tx) => {
  await Loader.load()

  const txUnsigned = Loader.Cardano.Transaction.from_bytes(Buffer.from(tx, "hex"))
  const txWitness = Loader.Cardano.TransactionWitnessSet.from_bytes(Buffer.from(witness, "hex"))

  return Buffer.from(
    Loader.Cardano.Transaction.new(txUnsigned.body(), txWitness, txUnsigned.auxiliary_data()).to_bytes()
  ).toString("hex")
}

export const withdrawalTx = async (utxos, stakeAddress, changeAddress, value, currentSlot) => {
  await Loader.load()

  const txBuilderConfig = Loader.Cardano.TransactionBuilderConfigBuilder.new()
    .coins_per_utxo_byte(Loader.Cardano.BigNum.from_str(protocolParameters.coinsPerUtxoWord))
    .fee_algo(
      Loader.Cardano.LinearFee.new(
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeA),
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeB)
      )
    )
    .key_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.keyDeposit))
    .pool_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.poolDeposit))
    .max_tx_size(protocolParameters.maxTxSize)
    .max_value_size(protocolParameters.maxValSize)
    .ex_unit_prices(Loader.Cardano.ExUnitPrices.from_float(0, 0))
    .collateral_percentage(protocolParameters.collateralPercentage)
    .max_collateral_inputs(protocolParameters.maxCollateralInputs)
    .build()

  const txBuilder = Loader.Cardano.TransactionBuilder.new(txBuilderConfig)

  txBuilder.add_withdrawal(
    Loader.Cardano.RewardAddress.from_address(Loader.Cardano.Address.from_bech32(stakeAddress)),
    Loader.Cardano.BigNum.from_str(value)
  )
  txBuilder.set_ttl(Loader.Cardano.BigNum.from_str((currentSlot + protocolParameters.ttl).toString()))
  const utxosCore = Loader.Cardano.TransactionUnspentOutputs.new()
  const _utxos = await utxoFromJson(utxos)
  _utxos.forEach((utxo) => utxosCore.add(utxo))
  txBuilder.add_inputs_from(utxosCore, Loader.Cardano.Address.from_bech32(changeAddress), protocolParameters.weights)
  txBuilder.balance(Loader.Cardano.Address.from_bech32(changeAddress))

  const fee = txBuilder.get_fee_if_set().to_str()
  const tx = await txBuilder.construct()
  const txHash = Loader.Cardano.hash_transaction(tx.body())

  return {
    txCbor: Buffer.from(tx.to_bytes()).toString("hex"),
    txHash: Buffer.from(txHash.to_bytes()).toString("hex"),
    fee,
  }
}

export const delegateTx = async (utxos, stakeAddress, changeAddress, currentSlot, poolId, hasStakingKey) => {
  await Loader.load()

  const txBuilderConfig = Loader.Cardano.TransactionBuilderConfigBuilder.new()
    .coins_per_utxo_byte(Loader.Cardano.BigNum.from_str(protocolParameters.coinsPerUtxoWord))
    .fee_algo(
      Loader.Cardano.LinearFee.new(
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeA),
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeB)
      )
    )
    .key_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.keyDeposit))
    .pool_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.poolDeposit))
    .max_tx_size(protocolParameters.maxTxSize)
    .max_value_size(protocolParameters.maxValSize)
    .ex_unit_prices(Loader.Cardano.ExUnitPrices.from_float(0, 0))
    .collateral_percentage(protocolParameters.collateralPercentage)
    .max_collateral_inputs(protocolParameters.maxCollateralInputs)
    .build()

  const txBuilder = Loader.Cardano.TransactionBuilder.new(txBuilderConfig)

  if (hasStakingKey) {
    txBuilder.add_certificate(
      Loader.Cardano.Certificate.new_stake_registration(
        Loader.Cardano.StakeRegistration.new(
          Loader.Cardano.StakeCredential.from_keyhash(
            Loader.Cardano.RewardAddress.from_address(Loader.Cardano.Address.from_bech32(stakeAddress))
              ?.payment_cred()
              .to_keyhash()
          )
        )
      )
    )
  }

  txBuilder.add_certificate(
    Loader.Cardano.Certificate.new_stake_delegation(
      Loader.Cardano.StakeDelegation.new(
        Loader.Cardano.StakeCredential.from_keyhash(
          Loader.Cardano.RewardAddress.from_address(Loader.Cardano.Address.from_bech32(stakeAddress))
            ?.payment_cred()
            .to_keyhash()
        ),
        Loader.Cardano.Ed25519KeyHash.from_bech32(poolId)
      )
    )
  )

  txBuilder.set_ttl(Loader.Cardano.BigNum.from_str((currentSlot + protocolParameters.ttl).toString()))
  const utxosCore = Loader.Cardano.TransactionUnspentOutputs.new()
  const _utxos = await utxoFromJson(utxos)
  _utxos.forEach((utxo) => utxosCore.add(utxo))
  txBuilder.add_inputs_from(utxosCore, Loader.Cardano.Address.from_bech32(changeAddress), protocolParameters.weights)
  txBuilder.balance(Loader.Cardano.Address.from_bech32(changeAddress))

  const fee = txBuilder.get_fee_if_set().to_str()
  const tx = await txBuilder.construct()
  const txHash = Loader.Cardano.hash_transaction(tx.body())

  return {
    txCbor: Buffer.from(tx.to_bytes()).toString("hex"),
    txHash: Buffer.from(txHash.to_bytes()).toString("hex"),
    fee,
  }
}

export const deregisterTx = async (utxos, stakeAddress, changeAddress, value, currentSlot) => {
  await Loader.load()

  const txBuilderConfig = Loader.Cardano.TransactionBuilderConfigBuilder.new()
    .coins_per_utxo_byte(Loader.Cardano.BigNum.from_str(protocolParameters.coinsPerUtxoWord))
    .fee_algo(
      Loader.Cardano.LinearFee.new(
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeA),
        Loader.Cardano.BigNum.from_str(protocolParameters.linearFee.minFeeB)
      )
    )
    .key_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.keyDeposit))
    .pool_deposit(Loader.Cardano.BigNum.from_str(protocolParameters.poolDeposit))
    .max_tx_size(protocolParameters.maxTxSize)
    .max_value_size(protocolParameters.maxValSize)
    .ex_unit_prices(Loader.Cardano.ExUnitPrices.from_float(0, 0))
    .collateral_percentage(protocolParameters.collateralPercentage)
    .max_collateral_inputs(protocolParameters.maxCollateralInputs)
    .build()

  const txBuilder = Loader.Cardano.TransactionBuilder.new(txBuilderConfig)

  if (Number(value) > 0) {
    txBuilder.add_withdrawal(
      Loader.Cardano.RewardAddress.from_address(Loader.Cardano.Address.from_bech32(stakeAddress)),
      Loader.Cardano.BigNum.from_str(value)
    )
  }

  txBuilder.add_certificate(
    Loader.Cardano.Certificate.new_stake_deregistration(
      Loader.Cardano.StakeDeregistration.new(
        Loader.Cardano.StakeCredential.from_keyhash(
          Loader.Cardano.RewardAddress.from_address(Loader.Cardano.Address.from_bech32(stakeAddress))
            ?.payment_cred()
            .to_keyhash()
        )
      )
    )
  )

  txBuilder.set_ttl(Loader.Cardano.BigNum.from_str((currentSlot + protocolParameters.ttl).toString()))
  const utxosCore = Loader.Cardano.TransactionUnspentOutputs.new()
  const _utxos = await utxoFromJson(utxos)
  _utxos.forEach((utxo) => utxosCore.add(utxo))
  txBuilder.add_inputs_from(utxosCore, Loader.Cardano.Address.from_bech32(changeAddress), protocolParameters.weights)
  txBuilder.balance(Loader.Cardano.Address.from_bech32(changeAddress))

  const fee = txBuilder.get_fee_if_set().to_str()
  const tx = await txBuilder.construct()
  const txHash = Loader.Cardano.hash_transaction(tx.body())

  return {
    txCbor: Buffer.from(tx.to_bytes()).toString("hex"),
    txHash: Buffer.from(txHash.to_bytes()).toString("hex"),
    fee,
  }
}
