import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { AccountActions, AccountTypes } from "./"
import config from "@/config/config"

import CGraphql, { CGraphqlTypes } from "@/services/graphql-ts-client"
import BigNumber from "bignumber.js"
import { notification } from "antd"
import { truncate } from "@/utils/utils"

// TODO: move to Ray.js
export const getAssetsSummaryFromUTXO = (utxos: CGraphqlTypes.IUtxo[]) => {
  const format = /^([/\\\[\]*<>(),.!?@+=%&'"|a-zA-Z0-9 _-]+)$/
  const assetsSummary: { value: string; tokens: { [assetId: string]: AccountTypes.BalanceToken } } = {
    value: "0",
    tokens: {},
  }
  utxos.forEach((utxo) => {
    assetsSummary.value = new BigNumber(assetsSummary.value).plus(utxo.value).toString()
    const { tokens } = utxo
    if (tokens.length) {
      tokens.forEach((token) => {
        const { asset, quantity } = token
        const { assetId } = asset
        if (!assetsSummary.tokens[assetId]) {
          assetsSummary.tokens[assetId] = {
            quantity: "0",
            asset: {
              assetId: "",
              assetName: null,
              assetNameAscii: "",
              fingerprint: "",
              policyId: "",
              decimals: null,
              // logo: null,
            },
          }
        }
        const assetNameAscii = Buffer.from(asset.assetName || "", "hex").toString("utf-8") || ""
        assetsSummary.tokens[assetId] = {
          quantity: new BigNumber(assetsSummary.tokens[assetId].quantity).plus(quantity).toString(),
          asset: {
            ...asset,
            assetNameAscii: format.test(assetNameAscii) ? assetNameAscii : asset.assetName,
          },
        }
      })
    }
  })

  return {
    value: assetsSummary.value,
    tokens: Object.keys(assetsSummary.tokens).map((key) => assetsSummary.tokens[key]),
  }
}

export function* ACCOUNT_INFO_UPDATE_SAGA({ loadingType, clear }: AccountTypes.AAccountInfoUpdateSaga) {
  if (clear) yield put(AccountActions.ACCOUNT_INFO_CLEAR())
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
  if (!accountCurrent) return

  const abortController = new AbortController()
  try {
    yield put(AccountActions.ACCOUNT_INFO_REQUEST(loadingType))
    const dataAccountInformation: {
      success?: AxiosResponse<KoiosTypes.AccountInfoResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountInfo,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )

    if (dataAccountInformation?.success) {
      const _dataAccountInformation = dataAccountInformation?.success?.data?.[0] || null
      const accountInfo: AccountTypes.AccountInfo = yield select((state: RootState) => state.account.accountInfo)
      if (accountInfo?.total_balance !== _dataAccountInformation?.total_balance) {
        yield put(AccountActions.BALANCE_UPDATE_SAGA())
      }
      if (
        _dataAccountInformation?.delegated_pool &&
        (accountInfo?.delegated_pool !== _dataAccountInformation?.delegated_pool || loadingType === "default")
      ) {
        const dataPoolInformation: {
          success?: AxiosResponse<KoiosTypes.PoolInfoResponse>
          error?: AxiosError
        } = yield call(
          Koios.PoolInfo,
          { _pool_bech32_ids: [_dataAccountInformation?.delegated_pool] },
          undefined,
          undefined,
          abortController.signal
        )
        if (dataPoolInformation?.success) {
          const _dataPoolInformation = dataPoolInformation?.success?.data?.[0]
          yield put(AccountActions.ACCOUNT_INFO_SUCCESS(_dataAccountInformation, _dataPoolInformation))
        }
        if (dataPoolInformation?.error) {
          yield console.log("dataPoolInformation :: error")
        }
      } else {
        yield put(AccountActions.ACCOUNT_INFO_SUCCESS(_dataAccountInformation))
      }
    }
    if (dataAccountInformation?.error) {
      yield console.log("dataAccountInformation :: error")
      yield put(AccountActions.ACCOUNT_INFO_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(AccountActions.ACCOUNT_INFO_FAILURE())
    }
  }
}

export function* BALANCE_UPDATE_SAGA({ clear }: AccountTypes.ABalanceUpdateSaga) {
  if (clear) yield put(AccountActions.BALANCE_CLEAR())
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
  if (!accountCurrent) return

  const abortController = new AbortController()
  try {
    yield put(AccountActions.BALANCE_REQUEST())
    const dataAccountAddresses: {
      success?: AxiosResponse<KoiosTypes.AccountAddressesResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountAddresses,
      {
        _stake_addresses: [accountCurrent.stakeKey],
        _empty: false,
      },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountAddresses?.success) {
      const _dataAccountAddresses = dataAccountAddresses?.success?.data?.[0].addresses || []
      const dataUtxoSetForAddresses: {
        success?: CGraphqlTypes.IUtxosResponse
        error?: CGraphqlTypes.IError
      } = yield call(CGraphql.utxoSetForAddresses, {
        addresses: _dataAccountAddresses,
        signal: abortController.signal,
      })
      if (dataUtxoSetForAddresses?.success) {
        const _balance = getAssetsSummaryFromUTXO(dataUtxoSetForAddresses?.success.utxos)

        yield put(
          AccountActions.BALANCE_SUCCESS(
            _balance.value,
            _balance.tokens,
            dataUtxoSetForAddresses?.success.utxos,
            _dataAccountAddresses
          )
        )
      }
      if (dataUtxoSetForAddresses?.error) {
        yield console.log("dataAccountAddresses :: error")
        yield put(AccountActions.BALANCE_FAILURE())
      }
    }
    if (dataAccountAddresses?.error) {
      yield console.log("dataAccountAddresses :: error")
      yield put(AccountActions.BALANCE_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(AccountActions.BALANCE_FAILURE())
    }
  }
}

export function* PENDING_TX_UPDATE_SAGA({ hash }: AccountTypes.APendingTxsUpdateSaga) {
  const pendingTxs: AccountTypes.PendingTxs = yield select((state: RootState) => state.account.pendingTxs)
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)

  yield put(
    AccountActions.PENDING_TXS_SET({
      ...pendingTxs,
      [accountCurrent?.id]: {
        hash,
        sent: false,
        timestamp: Date.now(),
      },
    })
  )
}

export function* PENDING_TX_REMOVE_SAGA() {
  const pendingTxs: AccountTypes.PendingTxs = yield select((state: RootState) => state.account.pendingTxs)
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)

  if (pendingTxs?.[accountCurrent?.id]) {
    const _pendingTxs = { ...pendingTxs }
    delete _pendingTxs[accountCurrent?.id]
    yield put(AccountActions.PENDING_TXS_SET(_pendingTxs))
  }
}

export function* PENDING_TXS_CHECK_SAGA() {
  const abortController = new AbortController()
  try {
    const pendingTxs: AccountTypes.PendingTxs = yield select((state: RootState) => state.account.pendingTxs)
    const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)

    if (Object.keys(pendingTxs || {}).length === 0 || !accountCurrent) return

    const _pendingTxs = { ...pendingTxs }

    Object.entries(_pendingTxs).forEach(([key, value]) => {
      if (Date.now() - value?.timestamp > config.ttl * 1000) {
        delete _pendingTxs[key]
        if (key === accountCurrent?.id) {
          notification.error({
            message: "Transaction Expired",
            description: `TX ID: ${truncate(value.hash)}`,
          })
        }
      }
    })

    const txsFound: {
      success?: AxiosResponse<KoiosTypes.TxStatusResponse>
      error?: AxiosError
    } = yield call(
      Koios.TxStatus,
      {
        _tx_hashes: Object.entries(_pendingTxs).map(([key, value]) => {
          return value.hash
        }),
      },
      undefined,
      undefined,
      abortController.signal
    )

    if (txsFound?.success) {
      const _txsFound = txsFound?.success?.data.reduce((acc, tx) => {
        if (tx.tx_hash && parseInt(tx.num_confirmations?.toString() || "0") >= 0) {
          return [...acc, tx.tx_hash]
        } else {
          return acc
        }
      }, [] as string[])

      if (_txsFound.length > 0) {
        const txsNotApplied = Object.entries(_pendingTxs).reduce((acc, [key, value]) => {
          if (_txsFound.includes(value.hash) && !value.sent) {
            if (key === accountCurrent?.id) {
              notification.success({
                message: "Tx Succesfully Sent",
                description: `TX ID: ${truncate(value.hash)}`,
              })
            }
            return {
              ...acc,
              [accountCurrent?.id]: {
                ...value,
                sent: true,
              },
            }
          }
          return { ...acc, [accountCurrent?.id]: value }
        }, {})

        yield put(AccountActions.PENDING_TXS_SET(txsNotApplied))
        yield put(AccountActions.ACCOUNT_INFO_UPDATE_SAGA("silent"))
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(AccountTypes.Enum.ACCOUNT_INFO_UPDATE_SAGA, ACCOUNT_INFO_UPDATE_SAGA),
    takeLatest(AccountTypes.Enum.BALANCE_UPDATE_SAGA, BALANCE_UPDATE_SAGA),
    takeLatest(AccountTypes.Enum.PENDING_TX_UPDATE_SAGA, PENDING_TX_UPDATE_SAGA),
    takeLatest(AccountTypes.Enum.PENDING_TX_REMOVE_SAGA, PENDING_TX_REMOVE_SAGA),
    takeLatest(AccountTypes.Enum.PENDING_TXS_CHECK_SAGA, PENDING_TXS_CHECK_SAGA),
  ])
}
