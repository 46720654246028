import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { TransactionActions, TransactionTypes } from "./"

export function* TEMP() {
  yield console.log("YAY!!!")
}

export default function* rootSaga() {
  yield all([
    // takeLatest(TransactionTypes.Enum.TEMP, TEMP),
  ])
}
