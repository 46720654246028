import React, { useEffect } from "react"
import { Link } from "gatsby"
import { truncate as truncateUtil } from "@/utils/utils"
import { useAppDispatch } from "@/redux/provider"
import * as style from "./style.module.scss"
import { useAppSelector } from "@/redux/provider"
import { SVGCardano, SVGAdaHandle } from "@/svg"
import { NetworkActions } from "@/redux/network"
import config from "@/config/config"

const TokenData = ({
  fingerprint,
  policyId,
  assetName,
  assetNameAscii,
  maxWidth = 250,
  disableUpdateCache,
}: {
  fingerprint?: string
  policyId: string
  assetName?: string | null
  assetNameAscii?: string
  maxWidth?: number
  disableUpdateCache?: boolean
}) => {
  const dispatch = useAppDispatch()
  const cachedTokens = useAppSelector((state) => state.network.cachedTokens)
  const token = cachedTokens[policyId + (assetName || "")]?.data
  const isHandle = policyId === config.adaHandlePolicyId
  const [showPlaceholder, setShowPlaceholder] = React.useState(true)

  useEffect(() => {
    if (!disableUpdateCache && fingerprint !== "lovelace") {
      dispatch(NetworkActions.CACHED_TOKENS_UPDATE_SAGA([{ policyId, assetName: assetName || null }]))
      console.log(123)
    }
  }, [])

  return (
    <div className={style.token} style={{ maxWidth }}>
      <div className={`${style.tokenLogo} ${fingerprint === "lovelace" ? style.tokenLogoLovelace : ""}`}>
        {/* @ts-ignore */}
        {token?.token_registry_metadata && (
          <img
            src={`${config.imagesUrl}/registry/64/${token.fingerprint}`}
            alt={assetNameAscii || token?.asset_name_ascii || ""}
            style={{ display: "none" }}
            onLoad={(event) => {
              // @ts-ignore
              event.target.style.display = "block"
              setShowPlaceholder(false)
            }}
          />
        )}
        {/* @ts-ignore */}
        {!token?.token_registry_metadata && token?.minting_tx_metadata?.["721"] && (
          <img
            src={`${config.imagesUrl}/721/64/${token.fingerprint}`}
            alt={assetNameAscii || token?.asset_name_ascii || ""}
            style={{ display: "none" }}
            onLoad={(event) => {
              // @ts-ignore
              event.target.style.display = "block"
              setShowPlaceholder(false)
            }}
          />
        )}
        {/* @ts-ignore */}
        {((!token?.token_registry_metadata && !token?.minting_tx_metadata?.["721"]) || showPlaceholder) && (
          <SVGCardano />
        )}
      </div>
      <span className={style.tokenTitle}>
        {isHandle && (
          <>
            <span className="ray-handle-icon">
              <SVGAdaHandle />
            </span>
            <span className="ray-handle-title">{assetNameAscii}</span>
          </>
        )}
        {!isHandle && <strong>{assetNameAscii || token?.asset_name_ascii || ""}</strong>}
      </span>
    </div>
  )
}

export default TokenData
