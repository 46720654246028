import { TransactionTypes } from "./"

export function CLEAR(): TransactionTypes.AClear {
  return { type: TransactionTypes.Enum.CLEAR }
}

export function MODAL_ADA_TRANSACTION_SET(
  modalAdaTransaction: TransactionTypes.ModalAdaTransaction
): TransactionTypes.AModalAdaTransactionSet {
  return { type: TransactionTypes.Enum.MODAL_ADA_TRANSACTION_SET, modalAdaTransaction }
}
