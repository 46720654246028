/**
 * Account Types
 */

import { KoiosTypes } from "@/services/koios-ts-client"

export type AccountInfoLoading = "default" | "silent" | undefined
export type AccountInfo = KoiosTypes.IAccountInfo
export type PoolInfo = KoiosTypes.IPoolInfo
export type BalanceToken = {
  quantity: string
  asset: {
    assetId: string
    assetName: string | null
    assetNameAscii: string | null
    fingerprint: string
    policyId: string
    decimals: number | null
    // logo: string | null
  }
}
export type BalanceUtxo = {
  transaction: {
    hash: string
  }
  address: string
  value: string
  index: number
  tokens: {
    quantity: string
    asset: {
      assetId: string
      assetName: string | null
      fingerprint: string
      policyId: string
      decimals: number | null
      // logo: string | null
    }
  }[]
}
export type PendingTx = string
export type PendingTxs = {
  [key: string]: {
    hash: string
    sent: boolean
    timestamp: number
  }
}

export interface ISettings {
  accountInfoLoading: AccountInfoLoading
  accountInfo: AccountInfo | null
  poolInfo: PoolInfo | null

  balanceLoading: boolean
  balanceBootstrapped: boolean
  balanceAda: string | undefined
  balanceTokens: BalanceToken[]
  balanceUtxos: BalanceUtxo[]
  balanceAddresses: string[]

  pendingTxs: PendingTxs | null
}

/**
 * Account Actions
 */

export enum Enum {
  CLEAR = "account/CLEAR",

  ACCOUNT_INFO_UPDATE_SAGA = "account/ACCOUNT_INFO_UPDATE_SAGA",
  ACCOUNT_INFO_CLEAR = "account/ACCOUNT_INFO_CLEAR",
  ACCOUNT_INFO_REQUEST = "account/ACCOUNT_INFO_REQUEST",
  ACCOUNT_INFO_FAILURE = "account/ACCOUNT_INFO_FAILURE",
  ACCOUNT_INFO_SUCCESS = "account/ACCOUNT_INFO_SUCCESS",

  BALANCE_UPDATE_SAGA = "account/BALANCE_UPDATE_SAGA",
  BALANCE_CLEAR = "account/BALANCE_CLEAR",
  BALANCE_REQUEST = "account/BALANCE_REQUEST",
  BALANCE_FAILURE = "account/BALANCE_FAILURE",
  BALANCE_SUCCESS = "account/BALANCE_SUCCESS",

  PENDING_TXS_SET = "account/PENDING_TXS_SET",
  PENDING_TX_UPDATE_SAGA = "account/PENDING_TX_UPDATE_SAGA",
  PENDING_TX_REMOVE_SAGA = "account/PENDING_TX_REMOVE_SAGA",
  PENDING_TXS_CHECK_SAGA = "account/PENDING_TXS_CHECK_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}
export type AAccountInfoUpdateSaga = {
  type: typeof Enum.ACCOUNT_INFO_UPDATE_SAGA
  loadingType: AccountInfoLoading
  clear?: boolean
}
export type AAccountInfoClear = {
  type: typeof Enum.ACCOUNT_INFO_CLEAR
}
export type AAccountInfoRequest = {
  type: typeof Enum.ACCOUNT_INFO_REQUEST
  loadingType: AccountInfoLoading
}
export type AAccountInfoFailure = {
  type: typeof Enum.ACCOUNT_INFO_FAILURE
}
export type AAccountInfoSuccess = {
  type: typeof Enum.ACCOUNT_INFO_SUCCESS
  accountInfo: AccountInfo | null
  poolInfo?: PoolInfo | null
}
export type ABalanceUpdateSaga = {
  type: typeof Enum.BALANCE_UPDATE_SAGA
  clear?: boolean
}
export type ABalanceClear = {
  type: typeof Enum.BALANCE_CLEAR
}
export type ABalanceRequest = {
  type: typeof Enum.BALANCE_REQUEST
}
export type ABalanceFailure = {
  type: typeof Enum.BALANCE_FAILURE
}
export type ABalanceSuccess = {
  type: typeof Enum.BALANCE_SUCCESS
  balanceAda: string | undefined
  balanceTokens: BalanceToken[]
  balanceUtxos: BalanceUtxo[]
  balanceAddresses: string[]
}
export type APendingTxsSet = {
  type: typeof Enum.PENDING_TXS_SET
  pendingTxs: PendingTxs
}
export type APendingTxsUpdateSaga = {
  type: typeof Enum.PENDING_TX_UPDATE_SAGA
  hash: string
}
export type APendingTxsRemoveSaga = {
  type: typeof Enum.PENDING_TX_REMOVE_SAGA
}
export type APendingTxsCheckSaga = {
  type: typeof Enum.PENDING_TXS_CHECK_SAGA
}

export type Actions =
  | AClear
  | AAccountInfoUpdateSaga
  | AAccountInfoClear
  | AAccountInfoRequest
  | AAccountInfoFailure
  | AAccountInfoSuccess
  | ABalanceUpdateSaga
  | ABalanceClear
  | ABalanceRequest
  | ABalanceFailure
  | ABalanceSuccess
  | APendingTxsSet
  | APendingTxsUpdateSaga
  | APendingTxsRemoveSaga
  | APendingTxsCheckSaga
