import LocalForage from "localforage"

/**
 * Settings Types
 */

export type Theme = "default" | "dark"
export type LocalForage = typeof LocalForage | null
export type Currency = "usd" | "eur" | "jpy" | "cny"
export interface ModalAccount {
  open: boolean
  type: "connect" | "account" | "settings" | ""
}
export type Account = {
  id: string
  connectorId: string
  checksum: string
  stakeKey: string
  type: "readonly" | "connector" | "hw"
  online: boolean
}
export interface ITokenRaw {
  policyId: string
  assetName: string | null
  assetNameAscii: string | null
  fingerprint: string
}
export interface IAdaHandle {
  [key: string]: ITokenRaw | null
}
export interface IAdaHandleDisabled {
  [key: string]: boolean
}
export interface IAdaHandleList {
  [key: string]: ITokenRaw[]
}

export interface ISettings {
  theme: Theme
  cookiesViewed: boolean
  modalAccount: ModalAccount
  accountCurrent: Account | null
  accountsList: Account[]
  dbTokensCache: LocalForage
  currency: Currency
  adaHandle: IAdaHandle
  adaHandleDisabled: IAdaHandleDisabled
  adaHandleList: IAdaHandleList
}

/**
 * Settings Actions
 */

export enum Enum {
  INIT = "settings/INIT",
  COOKIES_SET = "settings/COOKIES_SET",
  THEME_CHANGE = "settings/THEME_CHANGE",
  THEME_CHANGE_SAGA = "settings/THEME_CHANGE_SAGA",
  DB_TOKENS_CACHE_SET = "settings/DB_TOKENS_CACHE_SET",
  MODAL_ACCOUNT_SET = "settings/MODAL_ACCOUNT_SET",
  ACCOUNT_CURRENT_SET = "settings/ACCOUNT_CURRENT_SET",
  ACCOUNTS_LIST_SET = "settings/ACCOUNTS_LIST_SET",
  ACCOUNT_ADD_SAGA = "settings/ACCOUNT_ADD_SAGA",
  ACCOUNT_REMOVE_SAGA = "settings/ACCOUNT_REMOVE_SAGA",
  CONNECTORS_UPDATE_STATUS_SAGA = "settings/CONNECTORS_UPDATE_STATUS_SAGA",
  CURRENCY_SET = "settings/CURRENCY_SET",
  ADA_HANDLE_SET = "settings/ADA_HANDLE_SET",
  ADA_HANDLE_DISABLED_SET = "settings/ADA_HANDLE_DISABLED_SET",
  ADA_HANDLE_LIST_SET = "settings/ADA_HANDLE_LIST_SET",
  ADA_HANDLE_UPDATE_SAGA = "settings/ADA_HANDLE_UPDATE_SAGA",
}

export type AInit = {
  type: typeof Enum.INIT
}

export type ACookiesSet = {
  type: typeof Enum.COOKIES_SET
  cookiesViewed: boolean
}

export type AThemeChange = {
  type: typeof Enum.THEME_CHANGE
  theme: Theme
}

export type AThemeChangeSaga = {
  type: typeof Enum.THEME_CHANGE_SAGA
  theme: Theme
}

export type ADbTokensCacheSet = {
  type: typeof Enum.DB_TOKENS_CACHE_SET
  dbTokensCache: LocalForage
}

export type AModalAccountSet = {
  type: typeof Enum.MODAL_ACCOUNT_SET
  modalAccount: ModalAccount
}

export type AAccountCurrentSet = {
  type: typeof Enum.ACCOUNT_CURRENT_SET
  account: Account | null
}

export type AAccountsListSet = {
  type: typeof Enum.ACCOUNTS_LIST_SET
  accountsList: Account[]
}

export type AAccountAddSaga = {
  type: typeof Enum.ACCOUNT_ADD_SAGA
  account: Account
}

export type AAccountRemoveSaga = {
  type: typeof Enum.ACCOUNT_REMOVE_SAGA
  account: Account
}

export type AConnectorsUpdateStatusSaga = {
  type: typeof Enum.CONNECTORS_UPDATE_STATUS_SAGA
}

export type ACurrencySet = {
  type: typeof Enum.CURRENCY_SET
  currency: Currency
}

export type AAdaHandleSet = {
  type: typeof Enum.ADA_HANDLE_SET
  adaHandle: IAdaHandle
}

export type AAdaHandleDisabledSet = {
  type: typeof Enum.ADA_HANDLE_DISABLED_SET
  adaHandleDisabled: IAdaHandleDisabled
}

export type AAdaHandleListSet = {
  type: typeof Enum.ADA_HANDLE_LIST_SET
  adaHandleList: IAdaHandleList
}

export type AAdaHandleUpdateSaga = {
  type: typeof Enum.ADA_HANDLE_UPDATE_SAGA
  adaHandleList: ITokenRaw[]
}

export type Actions =
  | AInit
  | ACookiesSet
  | AThemeChange
  | AThemeChangeSaga
  | ADbTokensCacheSet
  | AModalAccountSet
  | AAccountCurrentSet
  | AAccountsListSet
  | AAccountAddSaga
  | AAccountRemoveSaga
  | AConnectorsUpdateStatusSaga
  | ACurrencySet
  | AAdaHandleSet
  | AAdaHandleDisabledSet
  | AAdaHandleListSet
  | AAdaHandleUpdateSaga
