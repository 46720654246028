import React, { useState, useEffect } from "react"
import { Statistic, Tooltip } from "antd"
import Jazzicon from "react-jazzicon"
import { amountWithCommas, numberFromString, truncate } from "@/utils/utils"
import * as style from "./style.module.scss"
import { useAppSelector, useAppDispatch } from "@/redux/provider"
import { SVGRay, SVGAdaHandle } from "@/svg"
import { Link } from "gatsby"
import { SettingsActions } from "@/redux/settings"

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const networkTip = useAppSelector((state) => state.network.tip)
  const networkEpochCurrent = useAppSelector((state) => state.network.epochCurrent)
  const accountsList = useAppSelector((state) => state.settings.accountsList)
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const adaHandle = useAppSelector((state) => state.settings.adaHandle)
  const adaHandleDisabled = useAppSelector((state) => state.settings.adaHandleDisabled)
  const balanceLoading = useAppSelector((state) => state.account.balanceLoading)
  const pendingTxs = useAppSelector((state) => state.account.pendingTxs)

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    const int = setInterval(() => {
      setAnimate(false)
    }, 1500)
    return () => {
      clearInterval(int)
    }
  }, [networkTip?.block_no])

  return (
    <div className={style.sidebar}>
      <div className="mb-3">
        <Tooltip title="by Ray Network" placement="right">
          <Link className="d-flex" to="/">
            <SVGRay className={style.logo} alt="RaySwap" />
          </Link>
        </Tooltip>
      </div>
      <div>
        <div>
          {accountsList.map((account) => {
            const isAdaHandleEnabled = !adaHandleDisabled[account?.id || ""] && adaHandle?.[account?.id || ""]
            const txPending = !!pendingTxs?.[account?.id || ""] && !pendingTxs?.[account?.id || ""]?.sent
            const loading = balanceLoading && account.id === accountCurrent?.id
            return (
              <div key={account.id}>
                <Tooltip
                  title={
                    <>
                      <div>
                        <strong className="me-1">
                          {isAdaHandleEnabled && (
                            <span style={{ position: "relative", top: "-2px" }}>
                              <span className="ray-handle-icon">
                                <SVGAdaHandle />
                              </span>
                              <span className="ray-handle-title">{adaHandle?.[account?.id || ""]?.assetNameAscii}</span>
                            </span>
                          )}
                          {!isAdaHandleEnabled && account?.checksum}
                        </strong>
                        <strong className="text-nowrap">
                          <sup className="d-inline-flex align-items-center justify-items-center">
                            {account.type === "hw" && (
                              <>
                                <span>{account.connectorId}</span>
                                <span className="ray-dot ray-dot-blue ms-1 me-1" />
                                <span>hardware</span>
                              </>
                            )}
                            {account.type === "readonly" && <span>{account.type}</span>}
                            {account.type === "connector" && (
                              <>
                                <span>{account.connectorId}</span>
                                {account.online && (
                                  <>
                                    <span className="ray-dot ms-1 me-1" />
                                    <span>online</span>
                                  </>
                                )}
                                {!account.online && <span className="ray-dot ray-dot-gray ms-1 me-1" />}
                              </>
                            )}
                          </sup>
                        </strong>
                      </div>
                      <div className="font-size-12 text-muted-mono">{truncate(account.stakeKey)}</div>
                    </>
                  }
                  placement="right"
                >
                  <div
                    className={`${style.avatar} ${account.id === accountCurrent?.id ? style.avatarCurrent : ""}`}
                    onClick={() => dispatch(SettingsActions.ACCOUNT_CURRENT_SET(account))}
                  >
                    <div className={`${style.avatarLoading} ${txPending || loading ? style.avatarLoadingActive : ""}`}>
                      <i className="ray-spinner ray-spinner-md" />
                    </div>
                    {account.type === "hw" && <span className="ray-dot ray-dot-blue" />}
                    {account.type === "connector" && account.online && <span className="ray-dot" />}
                    <Jazzicon diameter={28} seed={numberFromString(account.stakeKey)} />
                  </div>
                </Tooltip>
              </div>
            )
          })}
        </div>
        <div>
          <Tooltip title="Connect Wallet" placement="right">
            <div
              className={style.avatar}
              onClick={() => dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: true, type: "connect" }))}
            >
              <i className="ri ri-add" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={style.sidebarBottom}>
        <div>
          <span className={`${style.statusDot} ${style.statusDotGreen} ${animate ? style.statusDotAnimate : ""}`} />
        </div>
        <div className="text-nowrap">
          <div>Block: {amountWithCommas(networkTip?.block_no || 0)}</div>
          <div style={{ height: "13rem" }}>
            Epoch: {amountWithCommas(networkTip?.epoch_no || 0)} ·{" "}
            <div className="ray-count">
              <Statistic.Countdown
                value={new Date((networkEpochCurrent?.end_time || 0) * 1000).getTime()}
                format="D[d] HH[h] mm[m] ss[s]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
