import React, { useState, useEffect } from "react"
import Informer from "@/components/Informer"
import { AccountTypes } from "@/redux/account"
import { NetworkTypes } from "@/redux/network"
import * as style from "./style.module.scss"

const ViewSwitch = ({
  items,
}: {
  items:
    | {
        type: "lovelace"
        quantity: string
        help?: string
        prefix?: string
      }[]
    | {
        type: "simple"
        title: string
        ticker: string
        quantity: string
        decimals: number
        help?: string
        prefix?: string
      }[]
    | {
        type: "token"
        asset: AccountTypes.BalanceToken["asset"]
        asset_info: NetworkTypes.AssetInformation | null
        quantity: AccountTypes.BalanceToken["quantity"]
        help?: string
        prefix?: string
      }[]
}) => {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          {item.type === "lovelace" && (
            <div className={style.item}>
              <div>
                <Informer.TokenData
                  fingerprint="lovelace"
                  policyId="lovelace"
                  assetName="lovelace"
                  assetNameAscii="ADA"
                  disableUpdateCache
                />
              </div>
              <div className={style.dots} />
              <div className={style.amount}>
                {item.prefix && <span className={style.prefix}>{item.prefix}</span>}
                <Informer.TokenInline amount={item.quantity} decimals={6} small tokenName="ADA" />
              </div>
            </div>
          )}
          {item.type === "simple" && (
            <div className={style.item}>
              <div className={style.title}>{item.title}</div>
              <div className={style.dots} />
              <div className={style.amount}>
                {item.prefix && <span className={style.prefix}>{item.prefix}</span>}
                <Informer.TokenInline amount={item.quantity} decimals={6} small tokenName={item.ticker} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default ViewSwitch
