import React, { useEffect, useState } from "react"
import { Modal, Button, Select, Input, Space, Tooltip, message, Popconfirm, Tag, Col, Row, Switch } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import Jazzicon from "react-jazzicon"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { SVGAdaHandle } from "@/svg"
import { truncate, numberFromString, randomString } from "@/utils/utils"
import * as style from "./style.module.scss"
import { AccountActions, AccountTypes } from "@/redux/account"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"

const ModalWalletSettings = () => {
  const dispatch = useAppDispatch()
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const accountInfo = useAppSelector((state) => state.account.accountInfo)
  const appTheme = useAppSelector((state) => state.settings.theme)
  const appCurrency = useAppSelector((state) => state.settings.currency)
  const adaHandle = useAppSelector((state) => state.settings.adaHandle)
  const adaHandleDisabled = useAppSelector((state) => state.settings.adaHandleDisabled)
  const adaHandleList = useAppSelector((state) => state.settings.adaHandleList)

  const isAdaHandleEnabled = !adaHandleDisabled[accountCurrent?.id || ""]

  const switchAdaHandle = (checked: boolean) => {
    dispatch(
      SettingsActions.ADA_HANDLE_DISABLED_SET({
        ...adaHandleDisabled,
        [accountCurrent?.id || ""]: !checked,
      })
    )
  }

  const deregisterStakeKey = () => {
    console.log("deregisterStakeKey")
    dispatch(TransactionActions.MODAL_ADA_TRANSACTION_SET({ open: true, type: "deregister" }))
  }

  const removeAccount = () => {
    if (accountCurrent) {
      dispatch(SettingsActions.ACCOUNT_REMOVE_SAGA(accountCurrent))
      dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: false, type: "" }))
    }
  }

  return (
    <div>
      {/* <div className="mb-4 pb-1">
        <CopyToClipboard text={accountCurrent?.stakeKey || ""} onCopy={() => message.success("Copied")}>
          <Tooltip title="Copy Stake Key">
            <div className="ray-connect-item ray-connect-item-active">
              <span className="ray-connect-item-check">
                <span />
                <i className="ri ri-check" />
              </span>
              <span className="ray-connect-item-img">
                <Jazzicon diameter={30} seed={numberFromString(accountCurrent?.stakeKey || "")} />
              </span>
              <span className="ray-connect-item-title">
                <div>
                  <strong className="me-1">
                    {adaHandle?.[accountCurrent?.id || ""] && (
                      <span style={{ position: "relative", top: "-2px" }}>
                        <span className="ray-handle-icon">
                          <SVGAdaHandle />
                        </span>
                        <span className="ray-handle-title">
                          {adaHandle?.[accountCurrent?.id || ""]?.assetNameAscii}
                        </span>
                      </span>
                    )}
                    {!adaHandle?.[accountCurrent?.id || ""] && accountCurrent?.checksum}
                  </strong>
                  <strong className="text-nowrap">
                    <sup className="d-inline-flex align-items-center justify-items-center">
                      {accountCurrent?.type === "hw" && (
                        <>
                          <span>{accountCurrent?.connectorId}</span>
                          <span className="ray-dot ray-dot-blue ms-1 me-1" />
                          <span>hardware</span>
                        </>
                      )}
                      {accountCurrent?.type === "readonly" && <span>{accountCurrent?.type}</span>}
                      {accountCurrent?.type === "connector" && (
                        <>
                          <span>{accountCurrent?.connectorId}</span>
                          {accountCurrent?.online && (
                            <>
                              <span className="ray-dot ms-1 me-1" />
                              <span>online</span>
                            </>
                          )}
                          {!accountCurrent?.online && <span className="ray-dot ray-dot-gray ms-1 me-1" />}
                        </>
                      )}
                    </sup>
                  </strong>
                </div>
                <div className="font-size-12 text-muted-mono">{truncate(accountCurrent?.stakeKey || "")}</div>
              </span>
              <span className="ray-connect-item-status">
                <i className="ri ri-copy me-1" />
              </span>
            </div>
          </Tooltip>
        </CopyToClipboard>
      </div> */}
      <div className="mb-4">
        <h6 className="mb-3">Stake Key</h6>
        <Space.Compact className="w-100" size="large">
          <Input value={accountCurrent?.stakeKey} />
          <CopyToClipboard text={accountCurrent?.stakeKey} onCopy={() => message.success("Copied")}>
            <Tooltip title="Copy Stake Key">
              <Button type="default">
                <i className="ri ri-copy" />
              </Button>
            </Tooltip>
          </CopyToClipboard>
        </Space.Compact>
      </div>
      <div className="ray-line mt-4 pt-1 mb-4" />
      <div className="mb-4">
        <h6 className="mb-3">Theme</h6>
        <Select
          value={appTheme}
          onChange={(value) => dispatch(SettingsActions.THEME_CHANGE_SAGA(value))}
          className="w-100"
          size="large"
          options={[
            { value: "default", label: "Light" },
            { value: "dark", label: "Dark" },
          ]}
        />
      </div>
      <div className="mb-4">
        <h6 className="mb-3">Currency</h6>
        <Select
          value={appCurrency}
          onChange={(value) => dispatch(SettingsActions.CURRENCY_SET(value))}
          className="w-100"
          size="large"
          options={[
            { value: "usd", label: "USD — $" },
            { value: "eur", label: "EUR — €" },
            { value: "jpy", label: "JPY — ¥" },
            { value: "cny", label: "CNY — ¥" },
          ]}
        />
      </div>
      <div className="mb-4">
        <h6 className="mb-3 d-flex align-items-center">
          <span>AdaHandle</span>
          <span className="ms-auto">
            <Switch size="small" checked={isAdaHandleEnabled} onChange={switchAdaHandle} />
          </span>
        </h6>
        <Select
          value={adaHandle[accountCurrent?.id || ""]?.fingerprint || undefined}
          onChange={(value) =>
            dispatch(
              SettingsActions.ADA_HANDLE_SET({
                ...adaHandle,
                [accountCurrent?.id || ""]: adaHandleList[accountCurrent?.id || ""].filter(
                  (i) => i.fingerprint === value
                )[0],
              })
            )
          }
          className="w-100"
          size="large"
          placeholder="Handle NFT not found"
          disabled={!adaHandleList?.[accountCurrent?.id || ""]?.length || !isAdaHandleEnabled}
          options={adaHandleList?.[accountCurrent?.id || ""].map((i) => {
            return {
              value: i.fingerprint,
              label: (
                <>
                  <span className="ray-handle-icon">
                    <SVGAdaHandle />
                  </span>
                  <span className="ray-handle-title">{i.assetNameAscii}</span>
                </>
              ),
            }
          })}
        />
      </div>
      <div className="ray-line mt-4 mb-4 pt-2" />
      <div>
        <h6 className="mb-3">Danger Zone</h6>
        <div className={style.danger}>
          {accountInfo && accountInfo?.delegated_pool && accountCurrent?.type !== "readonly" && (
            <div className={style.dangerItem}>
              <div>
                <div className="text-mono mb-1">
                  <strong>Deregister Stake Key</strong>
                </div>
                <div className="text-muted lh-12">
                  Returns the 2 ADA deposit and deregisters your certificate. Not necessary if you want to re-delegate
                  ADA to another pool
                </div>
              </div>
              <div className="ms-auto ps-3">
                <Button size="large" className="ray-btn ray-btn-outline" onClick={deregisterStakeKey}>
                  <i className="ri ri-close me-2 text-danger" />
                  Deregister
                </Button>
              </div>
            </div>
          )}
          <div className={style.dangerItem}>
            <div>
              <div className="text-mono mb-1">
                <strong>Remove</strong>
              </div>
              <div className="text-muted lh-12">Wipe all wallet data from this device</div>
            </div>
            <div className="ms-auto ps-3">
              <Popconfirm
                placement="topRight"
                title="Erase wallet data from this device?"
                onConfirm={removeAccount}
                okText="Yes"
                cancelText="No"
              >
                <Button size="large" className="ray-btn ray-btn-outline">
                  <i className="ri ri-trash me-2 text-danger" />
                  Remove
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalWalletSettings
