import React, { useEffect, useState } from "react"
import { CheckCircleFilled } from "@ant-design/icons"
import { Button } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import Informer from "@/components/Informer"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"

const Success = () => {
  const dispatch = useAppDispatch()

  const pendingTxs = useAppSelector((state) => state.account.pendingTxs)
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const modalAdaTransaction = useAppSelector((state) => state.transaction.modalAdaTransaction)
  const tx = pendingTxs?.[accountCurrent?.id || ""]?.hash || ""

  return (
    <div className="ray-tx">
      <div className="ray-tx-head mb-0 pb-0">
        <div className="ray-tx-head-icon text-success">
          <CheckCircleFilled style={{ fontSize: 72 }} />
        </div>
        <div className="ray-tx-head-title mt-2">Transaction Sent</div>
        <div className="text-center text-muted mt-2">
          <Informer.LinkCardanoscan title={tx} link={tx} type="transaction" />
        </div>
        {(modalAdaTransaction.type === "delegate" || modalAdaTransaction.type === "deregister") && (
          <div className="text-center text-muted mt-2">
            Changes in the delegation will appear in the app within 10-20 seconds
          </div>
        )}
      </div>
      <div className="text-center mt-4 pt-2 mb-4">
        <Button
          size="large"
          onClick={() => dispatch(TransactionActions.MODAL_ADA_TRANSACTION_SET({ open: false, type: "" }))}
        >
          <span>Close</span>
        </Button>
      </div>
    </div>
  )
}

export default Success
