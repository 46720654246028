import { format, addDays, addHours } from "date-fns"

export const pageSizeToContentRange = (currentPage: number, pageSize: number) => {
  return `${(currentPage * pageSize).toString()}-${currentPage * pageSize + pageSize - 1}`
}

export const contentRangeToTotalResults = (contentRange: string) => {
  const [, poolListTotalResults]: string[] = contentRange.split("/")
  return parseInt(poolListTotalResults)
}

export const dateFormat = (date: string, mask: string) => {
  return format(new Date(date), mask)
}

export const dateFormatFull = (date: number) => {
  return format(new Date(date), "yyyy-MM-dd HH:mm")
}

export const randomString = () => (Math.random() + 1).toString(36).substring(2)

export const amountWithCommas = (number: number | string): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const amountFormat = (value: string, decimals: number = 6) => {
  if (decimals <= 0) {
    return {
      a: amountWithCommas(value),
      b: undefined,
      final: amountWithCommas(value),
    }
  }
  const a = amountWithCommas(value.slice(0, -decimals)) || "0"
  const b = value.slice(-decimals).padStart(decimals, "0")
  return {
    a,
    b,
    final: `${a}.${b.padEnd(decimals, "0")}`,
  }
}

export const truncate = (string: string, start: number = 8, end?: number): string => {
  return string.length > 3 ? `${string.substring(0, start)}...${string.slice(end ? -end : -start)}` : string
}

export const numberFromString = (string: string) => {
  const a = string.slice(7, 15)
  let encode = ""
  for (let i = 0; i < a.length; i += 1) {
    const x = a.slice(i, i + 1)
    encode += x.charCodeAt(0)
  }

  return parseInt(encode, 10)
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const numberInput = (value: string) => /^\d*\.?\d*$/.test(value)

export const randomHSL = () => {
  return `hsla(${~~(360 * Math.random())},70%,70%,1)`
}
