import { StakingTokensTypes } from "./"

export function CLEAR(): StakingTokensTypes.AClear {
  return { type: StakingTokensTypes.Enum.CLEAR }
}

export function ACCOUNT_HISTORY_UPDATE_SAGA(): StakingTokensTypes.AAccountActivityUpdateSaga {
  return { type: StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_UPDATE_SAGA }
}
export function ACCOUNT_ACTIVITY_CLEAR(): StakingTokensTypes.AAccountActivityClear {
  return { type: StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_CLEAR }
}
export function ACCOUNT_HISTORY_REQUEST(): StakingTokensTypes.AAccountActivityRequest {
  return { type: StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_REQUEST }
}
export function ACCOUNT_HISTORY_FAILURE(): StakingTokensTypes.AAccountActivityFailure {
  return { type: StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_FAILURE }
}
export function ACCOUNT_HISTORY_SUCCESS(
  accountActivity: StakingTokensTypes.AccountActivityItem[]
): StakingTokensTypes.AAccountActivitySuccess {
  return { type: StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_SUCCESS, accountActivity }
}
