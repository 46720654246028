import React, { ReactNode } from "react"
import { configureStore } from "@reduxjs/toolkit"
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import createSagaMiddleware from "redux-saga"

import rootReducers from "./reducers"
import rootSagas from "./sagas"
import Runner from "./effects"

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({ reducer: rootReducers, middleware: [sagaMiddleware] })
sagaMiddleware.run(rootSagas)

const ReduxProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider store={store}>
      <Runner>{children}</Runner>
    </Provider>
  )
}

export default ReduxProvider

export type RootState = ReturnType<typeof rootReducers>
export type AppDispatch = typeof store.dispatch

type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
