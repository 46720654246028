/**
 * StakingTokens Types
 */

export type PremiumPools = string[]
export type AccountActivityItem = any

export interface ISettings {
  accountActivityLoading: boolean
  accountActivity: AccountActivityItem[]
}

/**
 * StakingTokens Actions
 */

export enum Enum {
  CLEAR = "stakingTokens/CLEAR",

  ACCOUNT_ACTIVITY_UPDATE_SAGA = "stakingTokens/ACCOUNT_ACTIVITY_UPDATE_SAGA",
  ACCOUNT_ACTIVITY_CLEAR = "stakingTokens/ACCOUNT_ACTIVITY_CLEAR",
  ACCOUNT_ACTIVITY_REQUEST = "stakingTokens/ACCOUNT_ACTIVITY_REQUEST",
  ACCOUNT_ACTIVITY_FAILURE = "stakingTokens/ACCOUNT_ACTIVITY_FAILURE",
  ACCOUNT_ACTIVITY_SUCCESS = "stakingTokens/ACCOUNT_ACTIVITY_SUCCESS",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AAccountActivityUpdateSaga = {
  type: typeof Enum.ACCOUNT_ACTIVITY_UPDATE_SAGA
}

export type AAccountActivityClear = {
  type: typeof Enum.ACCOUNT_ACTIVITY_CLEAR
}

export type AAccountActivityRequest = {
  type: typeof Enum.ACCOUNT_ACTIVITY_REQUEST
}

export type AAccountActivityFailure = {
  type: typeof Enum.ACCOUNT_ACTIVITY_FAILURE
}

export type AAccountActivitySuccess = {
  type: typeof Enum.ACCOUNT_ACTIVITY_SUCCESS
  accountActivity: AccountActivityItem[]
}

export type Actions =
  | AClear
  | AAccountActivityUpdateSaga
  | AAccountActivityClear
  | AAccountActivityRequest
  | AAccountActivityFailure
  | AAccountActivitySuccess
