import { combineReducers } from "redux"
import settings from "./settings/reducers"
import network from "./network/reducers"
import account from "./account/reducers"
import stakingAda from "./stakingAda/reducers"
import stakingTokens from "./stakingTokens/reducers"
import transaction from "./transaction/reducers"

const rootReducers = combineReducers({
  settings,
  network,
  account,
  stakingAda,
  stakingTokens,
  transaction,
})

export default rootReducers
