import { all } from "redux-saga/effects"
import settings from "./settings/sagas"
import network from "./network/sagas"
import account from "./account/sagas"
import stakingAda from "./stakingAda/sagas"
import stakingTokens from "./stakingTokens/sagas"
import transaction from "./transaction/sagas"

export default function* rootSaga() {
  yield all([settings(), network(), account(), stakingAda(), stakingTokens(), transaction()])
}
