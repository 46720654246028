/* eslint-disable */
class Loader {
  async load() {
    if (this.CML) return
    this.CML = await import("./@emurgo/cardano-multiplatform-lib-browser/cardano_multiplatform_lib")
  }

  get Cardano() {
    return this.CML
  }
}

export default new Loader()
