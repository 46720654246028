import axios, { AxiosError, AxiosResponse, GenericAbortSignal } from "axios"
import * as RayGraphTypes from "./types"
export type * as RayGraphTypes from "./types"
import { premiumPools as _premiumPools } from "./premiumPools"
import { error } from "console"
import { delay } from "@/utils/utils"

// export const raygraphClient = axios.create({
//   baseURL: "https://api-premium.raygraph.io",
// })

// raygraphClient.interceptors.response.use(
//   (response: AxiosResponse): any => {
//     return {
//       success: response.data,
//       headers: response.headers,
//     }
//   },
//   (error: AxiosError): { error: RayGraphTypes.IError } => {
//     return {
//       error: {
//         type: error?.response ? "error" : error?.request ? "no-response" : "bad-request",
//         message: error.message,
//         name: error.name,
//         error,
//       },
//     }
//   }
// )

// export async function raw({ signal, url }: { signal?: GenericAbortSignal; url: string }) {
//   return raygraphClient.get(`${url}`, { signal })
// }

async function premiumPools({
  signal,
}: {
  signal?: GenericAbortSignal
  headers?: object
  paramsString?: string
}): Promise<{ success?: RayGraphTypes.PoolListResponse; error?: RayGraphTypes.IError }> {
  await delay(600)
  return {
    success: _premiumPools,
  }
}

export default {
  premiumPools,
}
