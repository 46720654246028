import { SettingsTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.settings."
const initialState: SettingsTypes.ISettings = {
  theme: "default",
  cookiesViewed: false,
  modalAccount: {
    open: false,
    type: "",
  },
  accountCurrent: null,
  accountsList: [],
  dbTokensCache: null,
  currency: "usd",
  adaHandle: {},
  adaHandleDisabled: {},
  adaHandleList: {},
}

export default (
  state: SettingsTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: SettingsTypes.Actions
): SettingsTypes.ISettings => {
  switch (action.type) {
    case SettingsTypes.Enum.COOKIES_SET:
      setPersistSetting(persistStorePrefix, "cookiesViewed", action.cookiesViewed)
      return { ...state, cookiesViewed: action.cookiesViewed }
    case SettingsTypes.Enum.THEME_CHANGE:
      setPersistSetting(persistStorePrefix, "theme", action.theme)
      return { ...state, theme: action.theme }
    case SettingsTypes.Enum.DB_TOKENS_CACHE_SET:
      return { ...state, dbTokensCache: action.dbTokensCache }
    case SettingsTypes.Enum.ACCOUNT_CURRENT_SET:
      setPersistSetting(persistStorePrefix, "accountCurrent", action.account)
      return { ...state, accountCurrent: action.account }
    case SettingsTypes.Enum.ACCOUNTS_LIST_SET:
      setPersistSetting(persistStorePrefix, "accountsList", action.accountsList)
      return { ...state, accountsList: action.accountsList }
    case SettingsTypes.Enum.MODAL_ACCOUNT_SET:
      return { ...state, modalAccount: action.modalAccount }
    case SettingsTypes.Enum.ADA_HANDLE_SET:
      setPersistSetting(persistStorePrefix, "adaHandle", action.adaHandle)
      return { ...state, adaHandle: action.adaHandle }

    case SettingsTypes.Enum.ADA_HANDLE_DISABLED_SET:
      setPersistSetting(persistStorePrefix, "adaHandleDisabled", action.adaHandleDisabled)
      return { ...state, adaHandleDisabled: action.adaHandleDisabled }

    case SettingsTypes.Enum.ADA_HANDLE_LIST_SET:
      setPersistSetting(persistStorePrefix, "adaHandleList", action.adaHandleList)
      return { ...state, adaHandleList: action.adaHandleList }
    case SettingsTypes.Enum.CURRENCY_SET:
      setPersistSetting(persistStorePrefix, "currency", action.currency)
      return { ...state, currency: action.currency }
    default:
      return state
  }
}
