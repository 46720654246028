import { SettingsTypes } from "./"

export function INIT(): SettingsTypes.AInit {
  return { type: SettingsTypes.Enum.INIT }
}

export function COOKIES_SET(cookiesViewed: boolean): SettingsTypes.ACookiesSet {
  return { type: SettingsTypes.Enum.COOKIES_SET, cookiesViewed }
}

export function THEME_CHANGE(theme: SettingsTypes.Theme): SettingsTypes.AThemeChange {
  return { type: SettingsTypes.Enum.THEME_CHANGE, theme }
}

export function THEME_CHANGE_SAGA(theme: SettingsTypes.Theme): SettingsTypes.AThemeChangeSaga {
  return { type: SettingsTypes.Enum.THEME_CHANGE_SAGA, theme }
}

export function DB_TOKENS_CACHE_SET(dbTokensCache: SettingsTypes.LocalForage): SettingsTypes.ADbTokensCacheSet {
  return { type: SettingsTypes.Enum.DB_TOKENS_CACHE_SET, dbTokensCache }
}

export function MODAL_ACCOUNT_SET(modalAccount: SettingsTypes.ModalAccount): SettingsTypes.AModalAccountSet {
  return { type: SettingsTypes.Enum.MODAL_ACCOUNT_SET, modalAccount }
}

export function ACCOUNT_CURRENT_SET(account: SettingsTypes.Account | null): SettingsTypes.AAccountCurrentSet {
  return { type: SettingsTypes.Enum.ACCOUNT_CURRENT_SET, account }
}

export function ACCOUNTS_LIST_SET(accountsList: SettingsTypes.Account[]): SettingsTypes.AAccountsListSet {
  return { type: SettingsTypes.Enum.ACCOUNTS_LIST_SET, accountsList }
}

export function ACCOUNT_ADD_SAGA(account: SettingsTypes.Account): SettingsTypes.AAccountAddSaga {
  return { type: SettingsTypes.Enum.ACCOUNT_ADD_SAGA, account }
}

export function ACCOUNT_REMOVE_SAGA(account: SettingsTypes.Account): SettingsTypes.AAccountRemoveSaga {
  return { type: SettingsTypes.Enum.ACCOUNT_REMOVE_SAGA, account }
}

export function CONNECTORS_UPDATE_STATUS_SAGA(): SettingsTypes.AConnectorsUpdateStatusSaga {
  return { type: SettingsTypes.Enum.CONNECTORS_UPDATE_STATUS_SAGA }
}

export function CURRENCY_SET(currency: SettingsTypes.Currency): SettingsTypes.ACurrencySet {
  return { type: SettingsTypes.Enum.CURRENCY_SET, currency }
}

export function ADA_HANDLE_SET(adaHandle: SettingsTypes.IAdaHandle): SettingsTypes.AAdaHandleSet {
  return { type: SettingsTypes.Enum.ADA_HANDLE_SET, adaHandle }
}

export function ADA_HANDLE_DISABLED_SET(
  adaHandleDisabled: SettingsTypes.IAdaHandleDisabled
): SettingsTypes.AAdaHandleDisabledSet {
  return { type: SettingsTypes.Enum.ADA_HANDLE_DISABLED_SET, adaHandleDisabled }
}

export function ADA_HANDLE_LIST_SET(adaHandleList: SettingsTypes.IAdaHandleList): SettingsTypes.AAdaHandleListSet {
  return { type: SettingsTypes.Enum.ADA_HANDLE_LIST_SET, adaHandleList }
}

export function ADA_HANDLE_UPDATE_SAGA(adaHandleList: SettingsTypes.ITokenRaw[]): SettingsTypes.AAdaHandleUpdateSaga {
  return { type: SettingsTypes.Enum.ADA_HANDLE_UPDATE_SAGA, adaHandleList }
}
