import React from "react"
import { Tooltip } from "antd"
import { amountFormat } from "@/utils/utils"
import * as style from "./style.module.scss"

const InformerToken = ({
  amount,
  tokenName,
  title,
  help,
  decimals,
  hideDecimals,
  shortened,
}: {
  amount: string
  title: string
  tokenName?: string
  help?: string
  decimals?: number
  hideDecimals?: boolean
  shortened?: boolean
}) => {
  const { a, b, final } = amountFormat(amount, decimals)
  const short =
    Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 2 }).format(
      Number(final.replaceAll(",", ""))
    ) || "0"

  return (
    <div className={style.informer}>
      <div className={style.body}>
        <strong>{!shortened ? a : <Tooltip title={`${final} ${tokenName}`}>{short}</Tooltip>}</strong>
        <span className={style.postfix}>
          {b && !hideDecimals && !shortened && <span>.{b}</span>} <span>{tokenName}</span>
        </span>
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="ri ri-info ms-1" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerToken
