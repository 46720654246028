import localforage from "localforage"

export const dbTokensCache = localforage.createInstance({
  name: "tokensCache",
})

dbTokensCache.config({
  driver: typeof window === "undefined" ? undefined : localforage.INDEXEDDB,
  version: 1.0,
})
