import { TransactionTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.transaction."
const initialState: TransactionTypes.ISettings = {
  modalAdaTransaction: {
    open: false,
    type: "",
  },
}

export default (
  state: TransactionTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: TransactionTypes.Actions
): TransactionTypes.ISettings => {
  switch (action.type) {
    case TransactionTypes.Enum.CLEAR:
      return initialState
    case TransactionTypes.Enum.MODAL_ADA_TRANSACTION_SET:
      return {
        ...state,
        modalAdaTransaction: action.modalAdaTransaction,
      }
    default:
      return state
  }
}
