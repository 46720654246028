export interface IConnector {
  connectorId: string
  active: boolean
  installed: boolean
  title: string
  img: string
  tags: string[]
}

export const connectors: IConnector[] = [
  {
    connectorId: "eternl",
    active: true,
    installed: false,
    title: "Eternl",
    img: "eternl.png",
    tags: ["popular"],
  },
  {
    connectorId: "nami",
    active: true,
    installed: false,
    title: "Nami",
    img: "nami.svg",
    tags: ["popular"],
  },
  {
    connectorId: "flint",
    active: true,
    installed: false,
    title: "Flint",
    img: "flint.svg",
    tags: ["popular"],
  },
  {
    connectorId: "raywallet",
    active: false,
    installed: false,
    title: "RayWallet",
    img: "raywallet.svg",
    tags: [],
  },
]
