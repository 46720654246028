import { AccountTypes } from "./"

export function CLEAR(): AccountTypes.AClear {
  return { type: AccountTypes.Enum.CLEAR }
}
export function ACCOUNT_INFO_UPDATE_SAGA(
  loadingType: AccountTypes.AccountInfoLoading,
  clear?: boolean
): AccountTypes.AAccountInfoUpdateSaga {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_UPDATE_SAGA, loadingType, clear }
}
export function ACCOUNT_INFO_CLEAR(): AccountTypes.AAccountInfoClear {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_CLEAR }
}
export function ACCOUNT_INFO_REQUEST(loadingType: AccountTypes.AccountInfoLoading): AccountTypes.AAccountInfoRequest {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_REQUEST, loadingType }
}
export function ACCOUNT_INFO_FAILURE(): AccountTypes.AAccountInfoFailure {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_FAILURE }
}
export function ACCOUNT_INFO_SUCCESS(
  accountInfo: AccountTypes.AccountInfo,
  poolInfo?: AccountTypes.PoolInfo
): AccountTypes.AAccountInfoSuccess {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_SUCCESS, accountInfo, poolInfo }
}
export function BALANCE_UPDATE_SAGA(clear?: boolean): AccountTypes.ABalanceUpdateSaga {
  return { type: AccountTypes.Enum.BALANCE_UPDATE_SAGA, clear }
}
export function BALANCE_CLEAR(): AccountTypes.ABalanceClear {
  return { type: AccountTypes.Enum.BALANCE_CLEAR }
}
export function BALANCE_REQUEST(): AccountTypes.ABalanceRequest {
  return { type: AccountTypes.Enum.BALANCE_REQUEST }
}
export function BALANCE_FAILURE(): AccountTypes.ABalanceFailure {
  return { type: AccountTypes.Enum.BALANCE_FAILURE }
}
export function BALANCE_SUCCESS(
  balanceAda: string | undefined,
  balanceTokens: AccountTypes.BalanceToken[],
  balanceUtxos: AccountTypes.BalanceUtxo[],
  balanceAddresses: string[]
): AccountTypes.ABalanceSuccess {
  return { type: AccountTypes.Enum.BALANCE_SUCCESS, balanceAda, balanceTokens, balanceUtxos, balanceAddresses }
}
export function PENDING_TXS_SET(pendingTxs: AccountTypes.PendingTxs): AccountTypes.APendingTxsSet {
  return { type: AccountTypes.Enum.PENDING_TXS_SET, pendingTxs }
}
export function PENDING_TX_UPDATE_SAGA(hash: string): AccountTypes.APendingTxsUpdateSaga {
  return { type: AccountTypes.Enum.PENDING_TX_UPDATE_SAGA, hash }
}
export function PENDING_TX_REMOVE_SAGA(): AccountTypes.APendingTxsRemoveSaga {
  return { type: AccountTypes.Enum.PENDING_TX_REMOVE_SAGA }
}
export function PENDING_TXS_CHECK_SAGA(): AccountTypes.APendingTxsCheckSaga {
  return { type: AccountTypes.Enum.PENDING_TXS_CHECK_SAGA }
}
