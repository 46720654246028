import React, { useEffect, useState } from "react"

const Preload = () => {
  return (
    <div className="ray-tx">
      <div className="ray-tx-head">
        <div className="ray-tx-head-icon">
          <i className="ri ri-sync ri-spin-slow text-success" />
        </div>
        <div className="ray-tx-head-title">Building Transaction...</div>
      </div>
    </div>
  )
}

export default Preload
