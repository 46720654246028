import React from "react"
import { Statistic, Tooltip, Progress } from "antd"
import * as style from "./style.module.scss"

const InformerCountdown = ({
  value,
  title,
  help,
  theme = "default",
}: {
  value: any
  title?: string
  help?: string
  theme?: string
}) => {
  const progressBgColor = theme === "default" ? "#e8e8f0" : "#292e40"
  const percent = Math.round((1 - (parseInt(value) - Date.now()) / 432000_000) * 1000) / 10
  return (
    <div className={style.informer}>
      <div className={style.body}>
        <strong>
          <div className="ray-count d-flex align-items-center">
            <span className="me-2">
              <Progress
                type="circle"
                size={20}
                strokeLinecap="butt"
                strokeWidth={25}
                trailColor={progressBgColor}
                strokeColor="#3fcb9b"
                percent={percent}
              />
            </span>
            <Statistic.Countdown value={value} format="D[d] HH[h] mm[m] ss[s]" />
          </div>
        </strong>
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="ri ri-info ms-1" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerCountdown
