import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import Pending from "./Pending"
import Success from "./Success"
import Withdraw from "./Withdraw"
import Delegate from "./Delegate"
import Deregister from "./Deregister"
import * as style from "./style.module.scss"
import { AccountActions, AccountTypes } from "@/redux/account"

const TransactionAda = () => {
  const dispatch = useAppDispatch()
  const modalAdaTransaction = useAppSelector((state) => state.transaction.modalAdaTransaction)
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const pendingTxs = useAppSelector((state) => state.account.pendingTxs)

  const pending = !!pendingTxs?.[accountCurrent?.id || ""]
  const success = !!pendingTxs?.[accountCurrent?.id || ""]?.sent

  return (
    <Modal
      title={null}
      open={modalAdaTransaction.open}
      onCancel={() => dispatch(TransactionActions.MODAL_ADA_TRANSACTION_SET({ open: false, type: "" }))}
      footer={null}
      width={440}
      closeIcon={<span className="ri ri-close" />}
      destroyOnClose
    >
      {!pending && !success && modalAdaTransaction.type === "withdraw" && <Withdraw />}
      {!pending && !success && modalAdaTransaction.type === "delegate" && <Delegate />}
      {!pending && !success && modalAdaTransaction.type === "deregister" && <Deregister />}
      {pending && !success && <Pending />}
      {success && <Success />}
    </Modal>
  )
}

export default TransactionAda
