import React, { useEffect, useState, useRef } from "react"
import { Modal, Button, Tabs, Input, Tooltip, message, Tag } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Informer from "@/components/Informer"
import Skeleton from "@/components/Loading/Skeleton"
import Jazzicon from "react-jazzicon"
import { truncate, numberFromString } from "@/utils/utils"
import AccountTokensTable from "@/components/_appTables/accountTokens"
import { SVGAdaHandle } from "@/svg"
import { NetworkActions, NetworkTypes } from "@/redux/network"
import config from "@/config/config"

const ModalAccount = () => {
  const dispatch = useAppDispatch()
  const searchInput = useRef<any>()

  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)
  const appCurrency = useAppSelector((state) => state.settings.currency)
  const adaHandle = useAppSelector((state) => state.settings.adaHandle)
  const accountInfoLoading = useAppSelector((state) => state.account.accountInfoLoading)
  const accountInfo = useAppSelector((state) => state.account.accountInfo)
  const poolInfo = useAppSelector((state) => state.account.poolInfo)
  const balanceLoading = useAppSelector((state) => state.account.balanceLoading)
  const balanceAda = useAppSelector((state) => state.account.balanceAda)
  const balanceTokens = useAppSelector((state) => state.account.balanceTokens)
  const cachedTokens = useAppSelector((state) => state.network.cachedTokens)
  const exchangeRates = useAppSelector((state) => state.network.exchangeRates)

  const [typeKey, setTypeKey] = useState("all")
  const [searchString, setSearchString] = useState("")

  useEffect(() => {
    const handleSearchFocus = (e: any) => {
      try {
        if (e.code === "Slash") searchInput.current?.focus()
      } catch {}
    }
    window?.addEventListener("keyup", handleSearchFocus)
    return () => {
      window?.removeEventListener("keyup", handleSearchFocus)
    }
  }, [])

  const restLoading = accountInfoLoading === "default" || balanceLoading

  const tokensAll = balanceTokens
    .map((token) => {
      const cachedToken = cachedTokens[token.asset.policyId + token.asset.assetName]
      return {
        ...token,
        asset_info: cachedToken?.data,
      }
    })
    .filter((i) => {
      const _search = searchString.toLowerCase()
      return (
        i.asset.assetNameAscii?.toLowerCase().includes(_search) ||
        i.asset.fingerprint?.toLowerCase().includes(_search) ||
        i.asset.policyId?.toLowerCase().includes(_search)
      )
    })

  const tokensFT = [...tokensAll].filter((i) => i.asset_info?.total_supply !== "1")
  const tokensNFT = [...tokensAll].filter((i) => i.asset_info?.total_supply === "1")

  const tokensList = (() => {
    if (typeKey === "ft") return tokensFT
    if (typeKey === "nft") return tokensNFT
    return tokensAll
  })()

  return (
    <div>
      {/* <div className="mb-4 pb-1">
        <CopyToClipboard text={accountCurrent?.stakeKey || ""} onCopy={() => message.success("Copied")}>
          <Tooltip title="Copy Stake Key">
            <div className="ray-connect-item ray-connect-item-info ray-connect-item-active">
              <span className="ray-connect-item-check">
                <span />
                <i className="ri ri-check" />
              </span>
              <span className="ray-connect-item-img">
                <Jazzicon diameter={30} seed={numberFromString(accountCurrent?.stakeKey || "")} />
              </span>
              <span className="ray-connect-item-title">
                <div>
                  <strong className="me-1">
                    {adaHandle?.[accountCurrent?.id || ""] && (
                      <span style={{ position: "relative", top: "-2px" }}>
                        <span className="ray-handle-icon">
                          <SVGAdaHandle />
                        </span>
                        <span className="ray-handle-title">
                          {adaHandle?.[accountCurrent?.id || ""]?.assetNameAscii}
                        </span>
                      </span>
                    )}
                    {!adaHandle?.[accountCurrent?.id || ""] && accountCurrent?.checksum}
                  </strong>
                  <strong className="text-nowrap">
                    <sup className="d-inline-flex align-items-center justify-items-center">
                      {accountCurrent?.type === "hw" && (
                        <>
                          <span>{accountCurrent?.connectorId}</span>
                          <span className="ray-dot ray-dot-blue ms-1 me-1" />
                          <span>hardware</span>
                        </>
                      )}
                      {accountCurrent?.type === "readonly" && <span>{accountCurrent?.type}</span>}
                      {accountCurrent?.type === "connector" && (
                        <>
                          <span>{accountCurrent?.connectorId}</span>
                          {accountCurrent?.online && (
                            <>
                              <span className="ray-dot ms-1 me-1" />
                              <span>online</span>
                            </>
                          )}
                          {!accountCurrent?.online && <span className="ray-dot ray-dot-gray ms-1 me-1" />}
                        </>
                      )}
                    </sup>
                  </strong>
                </div>
                <div className="font-size-12 text-muted-mono">{truncate(accountCurrent?.stakeKey || "")}</div>
              </span>
              <span className="ray-connect-item-status">
                <i className="ri ri-copy me-1" />
              </span>
            </div>
          </Tooltip>
        </CopyToClipboard>
      </div> */}
      <div>
        <Skeleton loading={restLoading}>
          <div className="overflow-hidden">
            <div className="d-flex align-items-center flex-wrap me--5">
              <div className="me-5 mb-4">
                <Informer.Pool
                  title="Delegated To"
                  poolId={accountInfo?.delegated_pool || ""}
                  ticker={poolInfo?.meta_json?.ticker || ""}
                  help="Your staking certificate is registered in this pool"
                />
              </div>
              <div className="me-5 mb-4">
                <Informer.Token
                  title="Account Balance"
                  tokenName="ADA"
                  amount={accountInfo?.total_balance || ""}
                  help="Your current balance, including unclaimed rewards"
                />
              </div>
              <div className="me-5 mb-4">
                <Informer.Currency
                  title="ADA Price"
                  amount={exchangeRates?.cardano?.[appCurrency] || 0}
                  currency={config.currencySymbols[appCurrency] || ""}
                  help="ADA current market price"
                />
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
      <div className="ray-line mt-1 mb-4 pb-2" />
      <div className="pb-3 d-flex">
        <div className="flex-grow-1 me-2 wm-500">
          <Input
            ref={searchInput}
            prefix={<i className="ri ri-search me-1 text-muted" />}
            suffix={<i className="ray-search-suffix" />}
            size="large"
            placeholder="Search by Asset Name, Fingerprint, or Policy ID"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            // readOnly={poolListLoading || poolListDataLoading}
            // className={`ray-opacity ${poolListLoading || poolListDataLoading ? "ray-opacity-active" : ""}`}
            allowClear
          />
        </div>
      </div>
      <Tabs
        className="ray-tabs-compact ray-tabs-empty"
        // destroyInactiveTabPane
        activeKey={typeKey}
        onChange={(key) => setTypeKey(key)}
        items={[
          {
            key: "all",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">All Tokens</span>
                <Tag color="blue" className="me-0">
                  {tokensAll.length}
                </Tag>
              </div>
            ),
          },
          {
            key: "ft",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">FT</span>
                <Tag color="blue" className="me-0">
                  {tokensFT.length}
                </Tag>
              </div>
            ),
          },
          {
            key: "nft",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">NFT</span>
                <Tag color="blue" className="me-0">
                  {tokensNFT.length}
                </Tag>
              </div>
            ),
          },
        ]}
      />
      <div>
        <AccountTokensTable data={tokensList} loading={balanceLoading} size="small" wide={false} pageSize={10} />
      </div>
    </div>
  )
}

export default ModalAccount
