import axios, { AxiosError, AxiosResponse, GenericAbortSignal } from "axios"
import * as CGraphqlTypes from "./types"
export * as CGraphqlTypes from "./types"

const client = axios.create({
  baseURL: "https://api-graphql.raygraph.io",
})

client.interceptors.response.use(
  (response: AxiosResponse): any => {
    return {
      success: response.data?.data,
      headers: response.headers,
    }
  },
  (error: AxiosError): { error: CGraphqlTypes.IError } => {
    return {
      error: {
        type: error.response ? "error" : error.request ? "no-response" : "bad-request",
        message: error.message,
        name: error.name,
        error: error,
      },
    }
  }
)

async function networkTip({
  signal,
}: {
  signal?: GenericAbortSignal
}): Promise<{ success: CGraphqlTypes.INetworkTip[]; error: CGraphqlTypes.IError }> {
  return client.post(
    "/",
    {
      query: `
      {
        cardano {
          tip {
            number
            slotNo
          }
          currentEpoch {
            number
            startedAt
            blocksCount
          }
        }
      }
    `,
    },
    { signal }
  )
}

async function accountWithdrawals({
  stakeKey,
  signal,
}: {
  stakeKey: string
  signal?: GenericAbortSignal
}): Promise<{ success: { withdrawals: CGraphqlTypes.IWithdrawalsResponse }; error: CGraphqlTypes.IError }> {
  return client.post(
    "/",
    {
      query: `
        query accountWithdrawalsInformation($stakeKey: StakeAddress) {
          withdrawals(where: { address: { _eq: $stakeKey } }) {
            amount
            transaction {
              hash
              block {
                forgedAt
                number
                epoch {
                  number
                }
              }
            }
          }
        }
    `,
      variables: {
        stakeKey,
      },
    },
    { signal }
  )
}

async function utxoSetForAddresses({
  addresses,
  signal,
}: {
  addresses: string[]
  signal?: GenericAbortSignal
}): Promise<{ success: { utxos: CGraphqlTypes.IUtxosResponse }; error: CGraphqlTypes.IError }> {
  return client.post(
    "/",
    {
      query: `
        query utxoSetForAddresses(
          $addresses: [String]!
        ) {
          utxos(
            where: { address: { _in: $addresses } }
          ) {
            transaction {
              hash
            }
            address
            value
            index
            tokens {
              quantity
              asset {
                assetId
                assetName
                fingerprint
                policyId
                decimals
              }
            }
          }
        }
    `,
      variables: {
        addresses,
      },
    },
    { signal }
  )
}

export default {
  accountWithdrawals,
  utxoSetForAddresses,
}
