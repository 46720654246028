import { AccountTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.account."
const initialState: AccountTypes.ISettings = {
  accountInfoLoading: undefined,
  accountInfo: null,
  poolInfo: null,

  balanceLoading: false,
  balanceBootstrapped: false,
  balanceAda: undefined,
  balanceTokens: [],
  balanceUtxos: [],
  balanceAddresses: [],

  pendingTxs: null,
}

export default (
  state: AccountTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: AccountTypes.Actions
): AccountTypes.ISettings => {
  switch (action.type) {
    case AccountTypes.Enum.CLEAR:
      return initialState
    case AccountTypes.Enum.ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        accountInfoLoading: action.loadingType,
      }
    case AccountTypes.Enum.ACCOUNT_INFO_CLEAR:
      return {
        ...state,
        accountInfoLoading: initialState.accountInfoLoading,
        accountInfo: initialState.accountInfo,
        poolInfo: initialState.poolInfo,
      }
    case AccountTypes.Enum.ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfoLoading: undefined,
        accountInfo: action.accountInfo,
        poolInfo: action.poolInfo ? action.poolInfo : state.poolInfo,
      }
    case AccountTypes.Enum.ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        accountInfoLoading: undefined,
      }
    case AccountTypes.Enum.BALANCE_REQUEST:
      return {
        ...state,
        balanceLoading: true,
      }
    case AccountTypes.Enum.BALANCE_CLEAR:
      return {
        ...state,
        balanceLoading: initialState.balanceLoading,
        balanceAda: initialState.balanceAda,
        balanceTokens: initialState.balanceTokens,
        balanceUtxos: initialState.balanceUtxos,
        balanceAddresses: initialState.balanceAddresses,
      }
    case AccountTypes.Enum.BALANCE_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        balanceBootstrapped: true,
        balanceAda: action.balanceAda,
        balanceTokens: action.balanceTokens,
        balanceUtxos: action.balanceUtxos,
        balanceAddresses: action.balanceAddresses,
      }
    case AccountTypes.Enum.BALANCE_FAILURE:
      return {
        ...state,
        balanceLoading: false,
      }
    case AccountTypes.Enum.PENDING_TXS_SET:
      setPersistSetting(persistStorePrefix, "pendingTxs", action.pendingTxs)
      return {
        ...state,
        pendingTxs: action.pendingTxs,
      }
    default:
      return state
  }
}
