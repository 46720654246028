/**
 * Account Types
 */

export interface ITx {
  hash: string
}

export interface ModalAdaTransaction {
  open: boolean
  type: "withdraw" | "delegate" | "deregister" | ""
  poolId?: string
  poolTicker?: string
}

export interface ISettings {
  modalAdaTransaction: ModalAdaTransaction
}

/**
 * Account Actions
 */

export enum Enum {
  CLEAR = "transaction/CLEAR",

  MODAL_ADA_TRANSACTION_SET = "transaction/MODAL_ADA_TRANSACTION_SET",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AModalAdaTransactionSet = {
  type: typeof Enum.MODAL_ADA_TRANSACTION_SET
  modalAdaTransaction: ModalAdaTransaction
}

export type Actions = AClear | AModalAdaTransactionSet
