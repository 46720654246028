import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Router } from "@reach/router"
import { SVGPremium } from "@/svg"
import { Tabs } from "antd"
import StakingAdaMyPosition from "@/components/_pages/StakingAda/MyPosition"
import StakingAdaPools from "@/components/_pages/StakingAda/Pools"
import StakingAdaPoolsPremium from "@/components/_pages/StakingAda/PoolsPremium"

const MainLayout = ({ children, location }: { children: any; location: any }) => {
  const defaultActiveKey = location.pathname.includes("/ada-staking/pool/") ? "/ada-staking/pool/" : location.pathname

  return (
    <>
      <div className="ray-title">
        <h1 className="ray-title-head">ADA Staking</h1>
      </div>
      <div className="mt--3" />
      <Tabs
        className="ray-tabs-nomore ray-tabs-links mb-3"
        destroyInactiveTabPane
        activeKey={defaultActiveKey}
        items={[
          {
            key: "/ada-staking/",
            label: <Link to="/ada-staking/">All Pools</Link>,
          },
          {
            key: "/ada-staking/premium/",
            label: (
              <Link to="/ada-staking/premium/" className="d-flex align-items-center">
                <span>Promoted Pools</span>
                <span className="ray-premium ms-1">
                  <SVGPremium />
                </span>
              </Link>
            ),
          },
          {
            key: "/ada-staking/my-position/",
            label: <Link to="/ada-staking/my-position/">My Position</Link>,
          },
        ]}
      />
      {children}
    </>
  )
}

export default MainLayout
