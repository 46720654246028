import React, { useEffect, useState } from "react"

const Error = ({ text }: { text: string }) => {
  return (
    <div className="ray-tx">
      <div className="ray-tx-head">
        <div className="ray-tx-head-icon">
          <i className="ri ri-close text-danger" />
        </div>
        <div className="ray-tx-head-title">Oops, an error occurred...</div>
        <div className="text-muted text-center mt-3">{text}</div>
      </div>
    </div>
  )
}

export default Error
