import { all, takeEvery, put, call, select, takeLatest } from "redux-saga/effects"
import { getConnectorsWithStatus } from "@/services/connectors"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "./"
import { dbTokensCache } from "@/services/localforage"
import { message } from "antd"

export function* THEME_CHANGE_SAGA({ theme }: SettingsTypes.AThemeChangeSaga) {
  const html = global.document.querySelector("html")!
  html.setAttribute("data-disable-transitions", "true")
  html.setAttribute("data-theme", theme)
  setTimeout(() => {
    html.removeAttribute("data-disable-transitions")
  }, 500)
  yield put(SettingsActions.THEME_CHANGE(theme))
}

export function* ACCOUNT_ADD_SAGA({ account }: SettingsTypes.AAccountAddSaga) {
  const accountsList: SettingsTypes.Account[] = yield select((state: RootState) => state.settings.accountsList)
  const alreadyAdded = accountsList.filter(
    (i) => i.stakeKey === account.stakeKey && i.connectorId === account.connectorId
  )[0]
  if (alreadyAdded) {
    message.info("Wallet already added")
    yield put(SettingsActions.ACCOUNT_CURRENT_SET(alreadyAdded))
    return
  }
  yield put(SettingsActions.ACCOUNTS_LIST_SET([...accountsList, account]))
  yield put(SettingsActions.ACCOUNT_CURRENT_SET(account))
  message.success("Wallet connected")
}

export function* ACCOUNT_REMOVE_SAGA({ account }: SettingsTypes.AAccountRemoveSaga) {
  const accountsList: SettingsTypes.Account[] = yield select((state: RootState) => state.settings.accountsList)
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
  const removeIndex = accountsList.map((account) => account.id).indexOf(account.id)
  const updatedWalletsList = [...accountsList]
  updatedWalletsList.splice(removeIndex, 1)
  yield put(SettingsActions.ACCOUNTS_LIST_SET(updatedWalletsList))
  if (account?.id === accountCurrent?.id) {
    yield put(SettingsActions.ACCOUNT_CURRENT_SET(updatedWalletsList[0]))
  }
  if (!updatedWalletsList.length) {
    yield put(SettingsActions.ACCOUNT_CURRENT_SET(null))
  }
  message.error("Wallet removed")
}

export function* CONNECTORS_UPDATE_STATUS_SAGA() {
  const connectorsOnline: object[] = yield call(getConnectorsWithStatus)
  const accountsList: SettingsTypes.Account[] = yield select((state: RootState) => state.settings.accountsList)
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
  const accountsListUpdated: SettingsTypes.Account[] = accountsList.map((account) => {
    return {
      ...account,
      online: connectorsOnline.some(
        (i: any) => i.stakeKey === account.stakeKey && i.connectorId === account.connectorId
      ),
    }
  })
  yield put(SettingsActions.ACCOUNTS_LIST_SET(accountsListUpdated))
  yield put(SettingsActions.ACCOUNT_CURRENT_SET(accountsListUpdated.find((i) => i.id === accountCurrent.id) || null))
}

export function* ADA_HANDLE_UPDATE_SAGA({ adaHandleList }: SettingsTypes.AAdaHandleUpdateSaga) {
  const adaHandle: SettingsTypes.IAdaHandle = yield select((state: RootState) => state.settings.adaHandle)
  const balanceBootstrapped: boolean = yield select((state: RootState) => state.account.balanceBootstrapped)

  if (!balanceBootstrapped) return

  const adaHandleListRaw: SettingsTypes.IAdaHandleList = yield select(
    (state: RootState) => state.settings.adaHandleList
  )
  const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)

  yield put(
    SettingsActions.ADA_HANDLE_LIST_SET({
      ...adaHandleListRaw,
      [accountCurrent?.id]: adaHandleList,
    })
  )

  if (
    adaHandleList.length > 0 &&
    (!adaHandle[accountCurrent.id] ||
      !adaHandleList.find((i) => i.fingerprint === adaHandle?.[accountCurrent.id || ""]?.fingerprint))
  ) {
    yield put(
      SettingsActions.ADA_HANDLE_SET({
        ...adaHandle,
        [accountCurrent?.id]: adaHandleList[0],
      })
    )
  }

  if (adaHandleList.length <= 0) {
    yield put(
      SettingsActions.ADA_HANDLE_SET({
        ...adaHandle,
        [accountCurrent?.id]: null,
      })
    )
  }
}

export function* INIT() {
  const theme: SettingsTypes.Theme = yield select((state: RootState) => state.settings.theme)
  yield put(SettingsActions.THEME_CHANGE_SAGA(theme))

  const dbTokensCacheProvider: SettingsTypes.LocalForage = dbTokensCache
  yield put(SettingsActions.DB_TOKENS_CACHE_SET(dbTokensCacheProvider))

  yield put(SettingsActions.CONNECTORS_UPDATE_STATUS_SAGA())
}

export default function* rootSaga() {
  yield all([
    takeLatest(SettingsTypes.Enum.THEME_CHANGE_SAGA, THEME_CHANGE_SAGA),
    takeLatest(SettingsTypes.Enum.ACCOUNT_ADD_SAGA, ACCOUNT_ADD_SAGA),
    takeLatest(SettingsTypes.Enum.ACCOUNT_REMOVE_SAGA, ACCOUNT_REMOVE_SAGA),
    takeLatest(SettingsTypes.Enum.CONNECTORS_UPDATE_STATUS_SAGA, CONNECTORS_UPDATE_STATUS_SAGA),
    takeEvery(SettingsTypes.Enum.ADA_HANDLE_UPDATE_SAGA, ADA_HANDLE_UPDATE_SAGA),
    takeLatest(SettingsTypes.Enum.INIT, INIT),
  ])
}
