import { StakingAdaTypes } from "./"

export function CLEAR(): StakingAdaTypes.AClear {
  return { type: StakingAdaTypes.Enum.CLEAR }
}

export function PREMIUM_POOLS_UPDATE_SAGA(): StakingAdaTypes.APremiumPoolsUpdateSaga {
  return { type: StakingAdaTypes.Enum.PREMIUM_POOLS_UPDATE_SAGA }
}
export function PREMIUM_POOLS_CLEAR(): StakingAdaTypes.APremiumPoolsClear {
  return { type: StakingAdaTypes.Enum.PREMIUM_POOLS_CLEAR }
}
export function PREMIUM_POOLS_REQUEST(): StakingAdaTypes.APremiumPoolsRequest {
  return { type: StakingAdaTypes.Enum.PREMIUM_POOLS_REQUEST }
}
export function PREMIUM_POOLS_FAILURE(): StakingAdaTypes.APremiumPoolsFailure {
  return { type: StakingAdaTypes.Enum.PREMIUM_POOLS_FAILURE }
}
export function PREMIUM_POOLS_SUCCESS(
  premiumPools: StakingAdaTypes.PremiumPools[]
): StakingAdaTypes.APremiumPoolsSuccess {
  return { type: StakingAdaTypes.Enum.PREMIUM_POOLS_SUCCESS, premiumPools }
}

export function ACCOUNT_HISTORY_UPDATE_SAGA(): StakingAdaTypes.AAccountHistoryUpdateSaga {
  return { type: StakingAdaTypes.Enum.ACCOUNT_HISTORY_UPDATE_SAGA }
}
export function ACCOUNT_HISTORY_CLEAR(): StakingAdaTypes.AAccountHistoryClear {
  return { type: StakingAdaTypes.Enum.ACCOUNT_HISTORY_CLEAR }
}
export function ACCOUNT_HISTORY_REQUEST(): StakingAdaTypes.AAccountHistoryRequest {
  return { type: StakingAdaTypes.Enum.ACCOUNT_HISTORY_REQUEST }
}
export function ACCOUNT_HISTORY_FAILURE(): StakingAdaTypes.AAccountHistoryFailure {
  return { type: StakingAdaTypes.Enum.ACCOUNT_HISTORY_FAILURE }
}
export function ACCOUNT_HISTORY_SUCCESS(
  accountHistory: StakingAdaTypes.AccountHistory[]
): StakingAdaTypes.AAccountHistorySuccess {
  return { type: StakingAdaTypes.Enum.ACCOUNT_HISTORY_SUCCESS, accountHistory }
}

export function ACCOUNT_REWARDS_UPDATE_SAGA(): StakingAdaTypes.AAccountRewardsUpdateSaga {
  return { type: StakingAdaTypes.Enum.ACCOUNT_REWARDS_UPDATE_SAGA }
}
export function ACCOUNT_REWARDS_CLEAR(): StakingAdaTypes.AAccountRewardsClear {
  return { type: StakingAdaTypes.Enum.ACCOUNT_REWARDS_CLEAR }
}
export function ACCOUNT_REWARDS_REQUEST(): StakingAdaTypes.AAccountRewardsRequest {
  return { type: StakingAdaTypes.Enum.ACCOUNT_REWARDS_REQUEST }
}
export function ACCOUNT_REWARDS_FAILURE(): StakingAdaTypes.AAccountRewardsFailure {
  return { type: StakingAdaTypes.Enum.ACCOUNT_REWARDS_FAILURE }
}
export function ACCOUNT_REWARDS_SUCCESS(
  accountRewards: StakingAdaTypes.AccountRewards[]
): StakingAdaTypes.AAccountRewardsSuccess {
  return { type: StakingAdaTypes.Enum.ACCOUNT_REWARDS_SUCCESS, accountRewards }
}

export function ACCOUNT_UPDATES_UPDATE_SAGA(clear?: boolean): StakingAdaTypes.AAccountUpdatesUpdateSaga {
  return { type: StakingAdaTypes.Enum.ACCOUNT_UPDATES_UPDATE_SAGA }
}
export function ACCOUNT_UPDATES_CLEAR(): StakingAdaTypes.AAccountUpdatesClear {
  return { type: StakingAdaTypes.Enum.ACCOUNT_UPDATES_CLEAR }
}
export function ACCOUNT_UPDATES_REQUEST(): StakingAdaTypes.AAccountUpdatesRequest {
  return { type: StakingAdaTypes.Enum.ACCOUNT_UPDATES_REQUEST }
}
export function ACCOUNT_UPDATES_FAILURE(): StakingAdaTypes.AAccountUpdatesFailure {
  return { type: StakingAdaTypes.Enum.ACCOUNT_UPDATES_FAILURE }
}
export function ACCOUNT_UPDATES_SUCCESS(
  accountUpdates: StakingAdaTypes.AccountUpdates[]
): StakingAdaTypes.AAccountUpdatesSuccess {
  return { type: StakingAdaTypes.Enum.ACCOUNT_UPDATES_SUCCESS, accountUpdates }
}

export function ACCOUNT_WITHDRAWALS_UPDATE_SAGA(): StakingAdaTypes.AAccountWithdrawalsUpdateSaga {
  return { type: StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_UPDATE_SAGA }
}
export function ACCOUNT_WITHDRAWALS_CLEAR(): StakingAdaTypes.AAccountWithdrawalsClear {
  return { type: StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_CLEAR }
}
export function ACCOUNT_WITHDRAWALS_REQUEST(): StakingAdaTypes.AAccountWithdrawalsRequest {
  return { type: StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_REQUEST }
}
export function ACCOUNT_WITHDRAWALS_FAILURE(): StakingAdaTypes.AAccountWithdrawalsFailure {
  return { type: StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_FAILURE }
}
export function ACCOUNT_WITHDRAWALS_SUCCESS(
  accountWithdrawals: StakingAdaTypes.AccountWithdrawals[]
): StakingAdaTypes.AAccountWithdrawalsSuccess {
  return { type: StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_SUCCESS, accountWithdrawals }
}

export function POOL_LIST_UPDATE_SAGA(paramsString?: string, headers?: any): StakingAdaTypes.APoolListUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_LIST_UPDATE_SAGA, paramsString, headers }
}
export function POOL_LIST_CLEAR(): StakingAdaTypes.APoolListClear {
  return { type: StakingAdaTypes.Enum.POOL_LIST_CLEAR }
}
export function POOL_LIST_REQUEST(): StakingAdaTypes.APoolListRequest {
  return { type: StakingAdaTypes.Enum.POOL_LIST_REQUEST }
}
export function POOL_LIST_FAILURE(): StakingAdaTypes.APoolListFailure {
  return { type: StakingAdaTypes.Enum.POOL_LIST_FAILURE }
}
export function POOL_LIST_SUCCESS(
  poolList: StakingAdaTypes.PoolList[],
  poolListTotalResults: number
): StakingAdaTypes.APoolListSuccess {
  return { type: StakingAdaTypes.Enum.POOL_LIST_SUCCESS, poolList, poolListTotalResults }
}

export function POOL_LIST_DATA_UPDATE_SAGA(poolIds: string[]): StakingAdaTypes.APoolListDataUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_LIST_DATA_UPDATE_SAGA, poolIds }
}
export function POOL_LIST_DATA_CLEAR(): StakingAdaTypes.APoolListDataClear {
  return { type: StakingAdaTypes.Enum.POOL_LIST_DATA_CLEAR }
}
export function POOL_LIST_DATA_REQUEST(): StakingAdaTypes.APoolListDataRequest {
  return { type: StakingAdaTypes.Enum.POOL_LIST_DATA_REQUEST }
}
export function POOL_LIST_DATA_FAILURE(): StakingAdaTypes.APoolListDataFailure {
  return { type: StakingAdaTypes.Enum.POOL_LIST_DATA_FAILURE }
}
export function POOL_LIST_DATA_SUCCESS(poolListData: StakingAdaTypes.PoolInfo[]): StakingAdaTypes.APoolListDataSuccess {
  return { type: StakingAdaTypes.Enum.POOL_LIST_DATA_SUCCESS, poolListData }
}

export function POOL_INFO_UPDATE_SAGA(poolId: string): StakingAdaTypes.APoolInfoUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_INFO_UPDATE_SAGA, poolId }
}
export function POOL_INFO_CLEAR(): StakingAdaTypes.APoolInfoClear {
  return { type: StakingAdaTypes.Enum.POOL_INFO_CLEAR }
}
export function POOL_INFO_REQUEST(): StakingAdaTypes.APoolInfoRequest {
  return { type: StakingAdaTypes.Enum.POOL_INFO_REQUEST }
}
export function POOL_INFO_FAILURE(): StakingAdaTypes.APoolInfoFailure {
  return { type: StakingAdaTypes.Enum.POOL_INFO_FAILURE }
}
export function POOL_INFO_SUCCESS(poolInfo: StakingAdaTypes.PoolInfo): StakingAdaTypes.APoolInfoSuccess {
  return { type: StakingAdaTypes.Enum.POOL_INFO_SUCCESS, poolInfo }
}

export function POOL_BLOCKS_UPDATE_SAGA(poolId: string, epochNo?: number): StakingAdaTypes.APoolBlocksUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_BLOCKS_UPDATE_SAGA, poolId, epochNo }
}
export function POOL_BLOCKS_CLEAR(): StakingAdaTypes.APoolBlocksClear {
  return { type: StakingAdaTypes.Enum.POOL_BLOCKS_CLEAR }
}
export function POOL_BLOCKS_REQUEST(): StakingAdaTypes.APoolBlocksRequest {
  return { type: StakingAdaTypes.Enum.POOL_BLOCKS_REQUEST }
}
export function POOL_BLOCKS_FAILURE(): StakingAdaTypes.APoolBlocksFailure {
  return { type: StakingAdaTypes.Enum.POOL_BLOCKS_FAILURE }
}
export function POOL_BLOCKS_SUCCESS(poolBlocks: StakingAdaTypes.PoolBlocks[]): StakingAdaTypes.APoolBlocksSuccess {
  return { type: StakingAdaTypes.Enum.POOL_BLOCKS_SUCCESS, poolBlocks }
}

export function POOL_HISTORY_UPDATE_SAGA(poolId: string): StakingAdaTypes.APoolHistoryUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_HISTORY_UPDATE_SAGA, poolId }
}
export function POOL_HISTORY_CLEAR(): StakingAdaTypes.APoolHistoryClear {
  return { type: StakingAdaTypes.Enum.POOL_HISTORY_CLEAR }
}
export function POOL_HISTORY_REQUEST(): StakingAdaTypes.APoolHistoryRequest {
  return { type: StakingAdaTypes.Enum.POOL_HISTORY_REQUEST }
}
export function POOL_HISTORY_FAILURE(): StakingAdaTypes.APoolHistoryFailure {
  return { type: StakingAdaTypes.Enum.POOL_HISTORY_FAILURE }
}
export function POOL_HISTORY_SUCCESS(poolHistory: StakingAdaTypes.PoolHistory[]): StakingAdaTypes.APoolHistorySuccess {
  return { type: StakingAdaTypes.Enum.POOL_HISTORY_SUCCESS, poolHistory }
}

export function POOL_DELEGATORS_UPDATE_SAGA(poolId: string): StakingAdaTypes.APoolDelegatorsUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_DELEGATORS_UPDATE_SAGA, poolId }
}
export function POOL_DELEGATORS_CLEAR(): StakingAdaTypes.APoolDelegatorsClear {
  return { type: StakingAdaTypes.Enum.POOL_DELEGATORS_CLEAR }
}
export function POOL_DELEGATORS_REQUEST(): StakingAdaTypes.APoolDelegatorsRequest {
  return { type: StakingAdaTypes.Enum.POOL_DELEGATORS_REQUEST }
}
export function POOL_DELEGATORS_FAILURE(): StakingAdaTypes.APoolDelegatorsFailure {
  return { type: StakingAdaTypes.Enum.POOL_DELEGATORS_FAILURE }
}
export function POOL_DELEGATORS_SUCCESS(
  poolDelegators: StakingAdaTypes.PoolDelegators[]
): StakingAdaTypes.APoolDelegatorsSuccess {
  return { type: StakingAdaTypes.Enum.POOL_DELEGATORS_SUCCESS, poolDelegators }
}

export function POOL_UPDATES_UPDATE_SAGA(poolId: string): StakingAdaTypes.APoolUpdatesUpdateSaga {
  return { type: StakingAdaTypes.Enum.POOL_UPDATES_UPDATE_SAGA, poolId }
}
export function POOL_UPDATES_CLEAR(): StakingAdaTypes.APoolUpdatesClear {
  return { type: StakingAdaTypes.Enum.POOL_UPDATES_CLEAR }
}
export function POOL_UPDATES_REQUEST(): StakingAdaTypes.APoolUpdatesRequest {
  return { type: StakingAdaTypes.Enum.POOL_UPDATES_REQUEST }
}
export function POOL_UPDATES_FAILURE(): StakingAdaTypes.APoolUpdatesFailure {
  return { type: StakingAdaTypes.Enum.POOL_UPDATES_FAILURE }
}
export function POOL_UPDATES_SUCCESS(poolUpdates: StakingAdaTypes.PoolUpdates[]): StakingAdaTypes.APoolUpdatesSuccess {
  return { type: StakingAdaTypes.Enum.POOL_UPDATES_SUCCESS, poolUpdates }
}
