import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import Connect from "./Connect"
import Settings from "./Settings"
import Account from "./Account"
import * as style from "./style.module.scss"

const ModalWallet = () => {
  const dispatch = useAppDispatch()
  const modalAccount = useAppSelector((state) => state.settings.modalAccount)
  const accountCurrent = useAppSelector((state) => state.settings.accountCurrent)

  return (
    <Modal
      title={null}
      open={modalAccount.open}
      onCancel={() => dispatch(SettingsActions.MODAL_ACCOUNT_SET({ open: false, type: "" }))}
      footer={null}
      width={650}
      closeIcon={<span className="ri ri-close" />}
      destroyOnClose
    >
      <Tabs
        className="ray-tabs-wide ray-tabs-compact ray-tabs-negative-margin ray-tabs-nomore"
        destroyInactiveTabPane
        defaultActiveKey={modalAccount.type}
        items={[
          {
            key: "connect",
            label: "Connect",
            children: <Connect />,
          },
          {
            key: "account",
            label: "Account",
            children: <Account />,
            disabled: !accountCurrent,
          },
          {
            key: "settings",
            label: "Settings",
            children: <Settings />,
            disabled: !accountCurrent,
          },
        ]}
      />
    </Modal>
  )
}

export default ModalWallet
