import "@/styles/style.scss"
import React from "react"
import ReduxProvider from "./src/redux/provider"
import ThemeProvider from "./src/theme"
import Layout from "./src/layouts/Main"
import LayoutAdaStaking from "./src/layouts/AdaStaking"

import type { GatsbyBrowser } from "gatsby"

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </ReduxProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  if (props.path.startsWith('/ada-staking') && !props.path.includes('/ada-staking/pool/'))
    return <Layout {...props}><LayoutAdaStaking {...props}>{element}</LayoutAdaStaking></Layout>
  return <Layout {...props}>{element}</Layout>
}

// show update button on service worker update
// export const onServiceWorkerUpdateReady = () => {
//   document?.getElementById('updater')?.classList.remove('d-none')
// }

// // prevent scroll to top on route change
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   // const { pathname } = location
//   // const scrollToTopRoutes = ['/', '/cardano/calculator/', '/cardano/faq/']
//   // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//   //   window.scrollTo(0, 0)
//   // }

//   return false
// }