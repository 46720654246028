import { StakingTokensTypes } from "./"

const initialState: StakingTokensTypes.ISettings = {
  accountActivityLoading: false,
  accountActivity: [],
}

export default (
  state: StakingTokensTypes.ISettings = initialState,
  action: StakingTokensTypes.Actions
): StakingTokensTypes.ISettings => {
  switch (action.type) {
    case StakingTokensTypes.Enum.CLEAR:
      return initialState
    case StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_CLEAR:
      return {
        ...state,
        accountActivityLoading: initialState.accountActivityLoading,
        accountActivity: initialState.accountActivity,
      }
    case StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_REQUEST:
      return {
        ...state,
        accountActivityLoading: true,
      }
    case StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_FAILURE:
      return {
        ...state,
        accountActivityLoading: false,
      }
    case StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_SUCCESS:
      return {
        ...state,
        accountActivityLoading: false,
        accountActivity: action.accountActivity,
      }
    default:
      return state
  }
}
