// extracted by mini-css-extract-plugin
export var avatar = "style-module--avatar--1225f";
export var avatarCurrent = "style-module--avatarCurrent--9f9e4";
export var avatarLoading = "style-module--avatarLoading--8c0fe";
export var avatarLoadingActive = "style-module--avatarLoadingActive--9a813";
export var logo = "style-module--logo--6a0c1";
export var rotation = "style-module--rotation--c1b3c";
export var sidebar = "style-module--sidebar--146f0";
export var sidebarBottom = "style-module--sidebarBottom--bcbc7";
export var statusDot = "style-module--statusDot--c1295";
export var statusDotAnimate = "style-module--statusDotAnimate--f840e";
export var statusDotBlue = "style-module--statusDotBlue--514d0";
export var statusDotGreen = "style-module--statusDotGreen--1d128";
export var wallets = "style-module--wallets--13b47";