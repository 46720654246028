import React, { useState, useEffect } from "react"
import { Button } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SettingsActions } from "@/redux/settings"
import * as style from "./style.module.scss"

const Cookies = () => {
  const dispatch = useAppDispatch()
  const cookiesViewed = useAppSelector((state) => state.settings.cookiesViewed)
  const [cookiesAnimation, setCookiesAnimation] = useState(false)

  useEffect(() => {
    if (!cookiesViewed) {
      setTimeout(() => {
        setCookiesAnimation(true)
      }, 2000)
    }
  }, [])

  return (
    <div className={`${style.cookies} ${cookiesViewed && "d-none"} ${cookiesAnimation && style.cookiesAnimated}`}>
      <h5 className="mb-3">
        <strong>
          This app does NOT use cookies or any metrics tracking tools{" "}
          <span role="img" aria-label="">
            🍪
          </span>
        </strong>
      </h5>
      <p className={style.description}>
        We are in the era of Web3 and therefore tracking statistics or integrating any other client-side scripts that track the user on this app is COMPLETELY absent.
      </p>
      <p className={style.description}>
      In addition, we refuse to put the app on Goole Play or the Apple App Store - use our pure PWA app and be sure that no one is spying on you! :D
      </p>
      <div className="d-flex align-items-center">
        <Button
          onClick={() => dispatch(SettingsActions.COOKIES_SET(true))}
          size="large"
          className="ray-btn ray-btn-dark me-3"
        >
          <i className="ri ri-check me-2" />
          <span>Gooooood!</span>
        </Button>
      </div>
    </div>
  )
}

export default Cookies
