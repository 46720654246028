import React from "react"
import { Tooltip } from "antd"
import * as style from "./style.module.scss"

const InformerSimple = ({ value, title, help }: { value: string | number; title?: string; help?: string }) => {
  return (
    <div className={style.informer}>
      <div className={style.body}>
        <strong>{value}</strong>
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="ri ri-info ms-1" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerSimple
