import React from "react"
import { Link } from "gatsby"
import { truncate } from "@/utils/utils"
import { Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"

const InformerLinkInner = ({
  title,
  link,
  truncate: _truncate,
}: {
  title: string
  link: string
  truncate?: number
}) => {
  return (
    <span className="ray-link" onClick={(e) => e.stopPropagation()}>
      <CopyToClipboard text={title} onCopy={() => message.success("Copied")}>
        <Tooltip title="Copy">
          <span className="cursor-pointer me-1">{truncate(title, _truncate, 4)}</span>
        </Tooltip>
      </CopyToClipboard>
      <Tooltip title="More Details">
        <Link to={link}>
          <i className="ri ri-arrow_up ri-rotate font-size-14" />
        </Link>
      </Tooltip>
    </span>
  )
}

export default InformerLinkInner
