import * as RayGraphTypes from "./types"

export const rayPools: RayGraphTypes.PoolListResponse = [
  {
    ticker: "RAY",
    pool_id_bech32: "pool1rjxdqghfjw5rv6lxg8qhedkechvfgnsqhl8rrzwck9g45n43yql",
  },
  {
    ticker: "RAY2",
    pool_id_bech32: "pool1ntfxj2jgvhzen8e86ed679ctukar3vj538ywyyq8ryld66jj4sx",
  },
  {
    ticker: "RAY3",
    pool_id_bech32: "pool1yt868wrp9s2x5pehe96del9m3nwasme62yw02vww3kg6zwzspcz",
  },
  {
    ticker: "RAY4",
    pool_id_bech32: "pool19ru9alj7n5anmzs0f9gpzlj8kaq44yhcp45mldjvldxck8f6yg6",
  },
  {
    ticker: "RAY5",
    pool_id_bech32: "pool1szy2tdjj8a4av365wk3q2fqg9srllmt0383e6v9p664ng076x9e",
  },
  {
    ticker: "RAY6",
    pool_id_bech32: "pool1r650ja5y4jy7xd6srjz8ujkumy8xpk8kqgrxu92g3nwyk5jf4zq",
  },
  {
    ticker: "RAY7",
    pool_id_bech32: "pool1e6rjpsl42hsc52zc9s2awhv2df50yzw43xtc7d0vranpc9ej8uj",
  },
  {
    ticker: "RAY8",
    pool_id_bech32: "pool16erldmmx5nvrwncujtp6lxs9tmvgxcpthd600pe6axm5z3p6522",
  },
]

export const premiumPools: RayGraphTypes.PoolListResponse = [...rayPools]
