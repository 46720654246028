import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import RayGraph, { RayGraphTypes } from "@/services/raygraph"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { StakingAdaActions, StakingAdaTypes } from "@/redux/stakingAda"
import { pageSizeToContentRange, contentRangeToTotalResults } from "@/utils/utils"

import CGraphql, { CGraphqlTypes } from "@/services/graphql-ts-client"

export function* ACCOUNT_HISTORY_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_HISTORY_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_HISTORY_REQUEST())
    const dataAccountHistory: {
      success?: AxiosResponse<KoiosTypes.AccountHistoryResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountHistory,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountHistory?.success) {
      const _data = dataAccountHistory?.success?.data?.[0]?.history || []
      yield put(StakingAdaActions.ACCOUNT_HISTORY_SUCCESS(_data))
    }
    if (dataAccountHistory?.error) {
      yield put(StakingAdaActions.ACCOUNT_HISTORY_FAILURE())
      yield console.log("dataAccountHistory :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_HISTORY_FAILURE())
    }
  }
}

export function* ACCOUNT_REWARDS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_REWARDS_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_REWARDS_REQUEST())
    const dataAccountRewards: {
      success?: AxiosResponse<KoiosTypes.AccountRewardsResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountRewards,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountRewards?.success) {
      const _data = dataAccountRewards?.success?.data?.[0]?.rewards || []
      yield put(StakingAdaActions.ACCOUNT_REWARDS_SUCCESS(_data))
    }
    if (dataAccountRewards?.error) {
      yield put(StakingAdaActions.ACCOUNT_REWARDS_FAILURE())
      yield console.log("dataAccountRewards :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_REWARDS_FAILURE())
    }
  }
}

export function* ACCOUNT_UPDATES_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_UPDATES_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_UPDATES_REQUEST())
    const dataAccountUpdates: {
      success?: AxiosResponse<KoiosTypes.AccountUpdatesResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountUpdates,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountUpdates?.success) {
      const _data = dataAccountUpdates?.success?.data?.[0].updates
      yield put(StakingAdaActions.ACCOUNT_UPDATES_SUCCESS(_data))
    }
    if (dataAccountUpdates?.error) {
      yield put(StakingAdaActions.ACCOUNT_UPDATES_FAILURE())
      yield console.log("dataAccountUpdates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_UPDATES_FAILURE())
    }
  }
}

export function* ACCOUNT_WITHDRAWALS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: SettingsTypes.Account = yield select((state: RootState) => state.settings.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_WITHDRAWALS_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_REQUEST())
    const dataAccountWithdrawals: {
      success?: CGraphqlTypes.IWithdrawalsResponse
      error?: CGraphqlTypes.IError
    } = yield call(CGraphql.accountWithdrawals, { stakeKey: accountCurrent.stakeKey, signal: abortController.signal })
    if (dataAccountWithdrawals?.success) {
      const _data = dataAccountWithdrawals?.success?.withdrawals
      yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_SUCCESS(_data))
    }
    if (dataAccountWithdrawals?.error) {
      yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_FAILURE())
      yield console.log("dataAccountUpdates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_FAILURE())
    }
  }
}

export function* PREMIUM_POOLS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.PREMIUM_POOLS_REQUEST())
    const dataPremiumPools: {
      success?: RayGraphTypes.PoolListResponse
      error?: RayGraphTypes.IError
    } = yield call(RayGraph.premiumPools, {
      signal: abortController.signal,
    })
    if (dataPremiumPools?.success) {
      const _data = dataPremiumPools?.success || []
      yield put(StakingAdaActions.PREMIUM_POOLS_SUCCESS(_data))
    }
    if (dataPremiumPools?.error) {
      yield console.log("dataPoolList :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.PREMIUM_POOLS_FAILURE())
    }
  }
}

export function* POOL_LIST_UPDATE_SAGA({ headers, paramsString }: StakingAdaTypes.APoolListUpdateSaga) {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_LIST_REQUEST())
    yield put(StakingAdaActions.POOL_LIST_DATA_REQUEST())
    const dataPoolList: {
      headers?: any
      success?: AxiosResponse<KoiosTypes.PoolListResponse>
      error?: AxiosError
    } = yield call(Koios.PoolList, paramsString, headers, abortController.signal)
    if (dataPoolList?.success) {
      const _data = dataPoolList?.success?.data || []
      const _totalResults = contentRangeToTotalResults(dataPoolList?.success?.headers?.["content-range"] || "")
      yield put(StakingAdaActions.POOL_LIST_SUCCESS(_data, _totalResults))
    }
    if (dataPoolList?.error) {
      yield put(StakingAdaActions.POOL_LIST_CLEAR())
      yield put(StakingAdaActions.POOL_LIST_DATA_CLEAR())
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
      yield put(StakingAdaActions.POOL_LIST_DATA_FAILURE())
      yield console.log("dataPoolList :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
      yield put(StakingAdaActions.POOL_LIST_DATA_FAILURE())
    }
  }
}

export function* POOL_LIST_DATA_UPDATE_SAGA({ poolIds }: StakingAdaTypes.APoolListDataUpdateSaga) {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_LIST_DATA_REQUEST())
    const dataPoolInformation: {
      success?: AxiosResponse<KoiosTypes.PoolInfoResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolInfo,
      {
        _pool_bech32_ids: poolIds,
      },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataPoolInformation?.success) {
      const _dataPoolInformation = dataPoolInformation?.success?.data
      yield put(StakingAdaActions.POOL_LIST_DATA_SUCCESS(_dataPoolInformation))
    }
    if (dataPoolInformation?.error) {
      yield console.log("dataPoolInformation :: error")
      yield put(StakingAdaActions.POOL_LIST_DATA_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_LIST_DATA_FAILURE())
    }
  }
}

export function* POOL_INFO_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolInfoUpdateSaga) {
  yield put(StakingAdaActions.POOL_INFO_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_INFO_REQUEST())
    const dataPoolInformation: {
      success?: AxiosResponse<KoiosTypes.PoolInfoResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolInfo,
      {
        _pool_bech32_ids: [poolId],
      },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataPoolInformation?.success) {
      const _dataPoolInformation = dataPoolInformation?.success?.data?.[0]
      yield put(StakingAdaActions.POOL_INFO_SUCCESS(_dataPoolInformation))
    }
    if (dataPoolInformation?.error) {
      yield console.log("dataPoolInformation :: error")
      yield put(StakingAdaActions.POOL_INFO_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_INFO_FAILURE())
    }
  }
}

export function* POOL_BLOCKS_UPDATE_SAGA({ poolId, epochNo }: StakingAdaTypes.APoolBlocksUpdateSaga) {
  yield put(StakingAdaActions.POOL_BLOCKS_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_BLOCKS_REQUEST())
    const dataPoolBlocks: {
      success?: AxiosResponse<KoiosTypes.PoolBlocksResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolBlocks,
      {
        _pool_bech32: poolId,
        _epoch_no: epochNo ? epochNo.toString() : undefined,
      },
      "&order=block_height.desc",
      undefined,
      abortController.signal
    )
    if (dataPoolBlocks?.success) {
      const _dataPoolBlocks = dataPoolBlocks?.success?.data
      yield put(StakingAdaActions.POOL_BLOCKS_SUCCESS(_dataPoolBlocks))
    }
    if (dataPoolBlocks?.error) {
      yield console.log("dataPoolBlocks :: error")
      yield put(StakingAdaActions.POOL_BLOCKS_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_BLOCKS_FAILURE())
    }
  }
}

export function* POOL_HISTORY_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolHistoryUpdateSaga) {
  yield put(StakingAdaActions.POOL_HISTORY_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_HISTORY_REQUEST())
    const dataPoolHistory: {
      success?: AxiosResponse<KoiosTypes.PoolHistoryResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolHistory,
      {
        _pool_bech32: poolId,
      },
      "&order=epoch_no.asc",
      undefined,
      abortController.signal
    )
    if (dataPoolHistory?.success) {
      const _dataPoolHistory = dataPoolHistory?.success?.data
      yield put(StakingAdaActions.POOL_HISTORY_SUCCESS(_dataPoolHistory))
    }
    if (dataPoolHistory?.error) {
      yield console.log("dataPoolHistory :: error")
      yield put(StakingAdaActions.POOL_HISTORY_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_HISTORY_FAILURE())
    }
  }
}

export function* POOL_DELEGATORS_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolDelegatorsUpdateSaga) {
  yield put(StakingAdaActions.POOL_DELEGATORS_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_DELEGATORS_REQUEST())
    const dataPoolDelegators: {
      success?: AxiosResponse<KoiosTypes.PoolDelegatorsResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolDelegators,
      {
        _pool_bech32: poolId,
      },
      "&order=active_epoch_no.desc&limit=30",
      undefined,
      abortController.signal
    )
    if (dataPoolDelegators?.success) {
      const _dataPoolHistory = dataPoolDelegators?.success?.data
      yield put(StakingAdaActions.POOL_DELEGATORS_SUCCESS(_dataPoolHistory))
    }
    if (dataPoolDelegators?.error) {
      yield console.log("dataPoolDelegators :: error")
      yield put(StakingAdaActions.POOL_DELEGATORS_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_DELEGATORS_FAILURE())
    }
  }
}

export function* POOL_UPDATES_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolUpdatesUpdateSaga) {
  yield put(StakingAdaActions.POOL_UPDATES_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_UPDATES_REQUEST())
    const dataPoolUpdates: {
      success?: AxiosResponse<KoiosTypes.PoolUpdatesResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolUpdates,
      { _pool_bech32: poolId },
      undefined, // paramsString: "&order=epoch_no.asc",
      undefined,
      abortController.signal
    )
    if (dataPoolUpdates?.success) {
      const _dataPoolUpdates = dataPoolUpdates?.success?.data
      yield put(StakingAdaActions.POOL_UPDATES_SUCCESS(_dataPoolUpdates))
    }
    if (dataPoolUpdates?.error) {
      yield console.log("dataPoolUpdates :: error")
      yield put(StakingAdaActions.POOL_UPDATES_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_UPDATES_FAILURE())
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_HISTORY_UPDATE_SAGA, ACCOUNT_HISTORY_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_REWARDS_UPDATE_SAGA, ACCOUNT_REWARDS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_UPDATES_UPDATE_SAGA, ACCOUNT_UPDATES_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_UPDATE_SAGA, ACCOUNT_WITHDRAWALS_UPDATE_SAGA),

    takeLatest(StakingAdaTypes.Enum.PREMIUM_POOLS_UPDATE_SAGA, PREMIUM_POOLS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_LIST_UPDATE_SAGA, POOL_LIST_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_LIST_DATA_UPDATE_SAGA, POOL_LIST_DATA_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_INFO_UPDATE_SAGA, POOL_INFO_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_BLOCKS_UPDATE_SAGA, POOL_BLOCKS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_HISTORY_UPDATE_SAGA, POOL_HISTORY_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_DELEGATORS_UPDATE_SAGA, POOL_DELEGATORS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_UPDATES_UPDATE_SAGA, POOL_UPDATES_UPDATE_SAGA),
  ])
}
