import axios, { Axios, AxiosError, AxiosResponse, GenericAbortSignal } from "axios"

const client = axios.create({
  baseURL: "https://api.coingecko.com/api/v3",
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

client.interceptors.response.use(
  (response: AxiosResponse): any => {
    return {
      success: response,
    }
  },
  (error: AxiosError) => {
    return {
      error,
    }
  }
)

async function GetRawUrl(
  url: string,
  signal?: GenericAbortSignal
): Promise<{ success: AxiosResponse<any>; error: AxiosError }> {
  return client.get(url, { signal })
}

export default {
  GetRawUrl,
}
