export default {
  apiUrl: "https://output.mainnet.raygraph.io",
  imagesUrl: "https://cdn.mainnet.raygraph.io",
  adaHandlePolicyId: "f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a",
  currencySymbols: {
    usd: "$",
    eur: "€",
    jpy: "¥",
    cny: "¥",
  },
  ttl: 1800,
}
