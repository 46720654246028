import { all, takeEvery, put, call, select, cancelled, takeLatest } from "redux-saga/effects"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import Coingecko from "@/services/coingecko"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { NetworkActions, NetworkTypes } from "./"

export function* NETWORK_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const networkTip: {
      success?: AxiosResponse<KoiosTypes.TipResponse>
      error?: AxiosError
    } = yield call(Koios.Tip, undefined, undefined, abortController.signal)
    if (networkTip?.success) {
      const networkTipData = networkTip?.success?.data?.[0] || null
      yield put(NetworkActions.TIP_SET(networkTipData))
      const networkEpochCurrent: NetworkTypes.EpochCurrent = yield select(
        (state: RootState) => state.network.epochCurrent
      )
      if (networkEpochCurrent?.epoch_no !== networkTipData.epoch_no) {
        const epochInformation: {
          success?: AxiosResponse<KoiosTypes.EpochInfoResponse>
          error?: AxiosError
        } = yield call(
          Koios.EpochInfo,
          { _epoch_no: networkTipData?.epoch_no.toString() },
          undefined,
          undefined,
          abortController.signal
        )
        if (epochInformation?.success) {
          const epochInformationData = epochInformation?.success?.data?.[0] || null
          yield put(NetworkActions.EPOCH_CURRENT_SET(epochInformationData))
        }
        if (epochInformation?.error) {
          yield console.log("epochInformation :: error")
        }
      }
    }
    if (networkTip?.error) {
      yield console.log("networkTip :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export function* CACHED_TOKENS_UPDATE_SAGA({ tokensToAdd }: NetworkTypes.ACachedTokensUpdateSaga) {
  // checking tokens in browser cache and processing tokens that are not in cache
  const cachedTokens: NetworkTypes.ICachedTokens = yield select((state: RootState) => state.network.cachedTokens)
  const tokensToAddNotCached = tokensToAdd.filter(
    (token) => !Object.keys(cachedTokens).includes(token.policyId + (token.assetName || ""))
  )
  if (!tokensToAddNotCached.length) return
  // getting tokens from cache and detecting what should be loaded from the network
  const dbTokensCache: SettingsTypes.LocalForage = yield select((state: RootState) => state.settings.dbTokensCache)
  const storedTokens: NetworkTypes.IAssetInformationCached[] = yield all(
    tokensToAddNotCached.map((i) =>
      dbTokensCache ? call([dbTokensCache, dbTokensCache.getItem], i.policyId + (i.assetName || "")) : undefined
    )
  )
  const [tokensFromStore, tokensFromNetwork] = tokensToAdd.reduce(
    (acc, i) => {
      const _i = storedTokens.find(
        (item) => item?.data?.policy_id + (item?.data?.asset_name || "") === i.policyId + (i.assetName || "")
      )
      _i ? acc[0].push(_i) : acc[1].push(i)
      return acc
    },
    [[], []] as [
      NetworkTypes.IAssetInformationCached[],
      {
        policyId: string
        assetName: string | null
      }[]
    ]
  )
  const _tokensFromStore = tokensFromStore.reduce((acc, i) => {
    const assetId = i?.data?.policy_id + (i?.data?.asset_name || "")
    acc[assetId] = i
    return acc
  }, {} as NetworkTypes.ICachedTokens)
  yield put(NetworkActions.CACHED_TOKENS_ADD(_tokensFromStore))
  // getting tokens from network and saving to cache
  if (!tokensFromNetwork.length) return
  const tokensToUpdateArray = tokensFromNetwork.map((i) => [i.policyId, i.assetName || ""]) as [string, string][]
  const tokensToUpdateIdArray = tokensFromNetwork.map((i) => i.policyId + (i.assetName || "")) as string[]
  yield put(
    NetworkActions.CACHED_TOKENS_ADD(
      tokensFromNetwork.reduce((acc, i) => {
        const assetId = i.policyId + (i.assetName || "")
        acc[assetId] = {
          date: Date.now(),
          loading: true,
          data: null,
        }
        return acc
      }, {} as NetworkTypes.ICachedTokens)
    )
  )
  try {
    const dataAssetInfo: {
      success?: AxiosResponse<KoiosTypes.AssetInfoBulkResponse>
      error?: AxiosError
    } = yield call(Koios.AssetInfoBulk, {
      _asset_list: tokensToUpdateArray,
    })
    if (dataAssetInfo?.success) {
      // update with data response
      const _data = dataAssetInfo?.success?.data?.reduce((acc, i) => {
        const assetId = i.policy_id + (i.asset_name || "")
        acc[assetId] = {
          date: Date.now(),
          loading: false,
          data: i,
        }
        return acc
      }, {} as NetworkTypes.ICachedTokens)
      yield all(
        Object.entries(_data).map(([key, value]) =>
          dbTokensCache ? call([dbTokensCache, dbTokensCache.setItem], key, value) : undefined
        )
      )
      yield put(NetworkActions.CACHED_TOKENS_ADD(_data))
    }
    if (dataAssetInfo?.error) {
      // clear tokens on error
      yield put(NetworkActions.CACHED_TOKENS_REMOVE(tokensToUpdateIdArray))
      yield console.log("dataAssetInfo :: error")
    }
  } catch {
    // clear tokens on error
    yield put(NetworkActions.CACHED_TOKENS_REMOVE(tokensToUpdateIdArray))
  }
}

export function* EXCHANGE_RATES_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const exchangeRates: {
      success?: AxiosResponse<NetworkTypes.IExchangeRates>
      error?: AxiosError
    } = yield call(
      Coingecko.GetRawUrl,
      "/simple/price?ids=bitcoin,cardano,ray-network,ethereum&vs_currencies=USD,EUR,JPY,CNY",
      abortController.signal
    )
    if (exchangeRates?.success) {
      const exchangeRatesData = exchangeRates?.success?.data || {}
      yield put(NetworkActions.EXCHANGE_RATES_SET(exchangeRatesData))
    }
    if (exchangeRates?.error) {
      yield console.log("exchangeRates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(NetworkTypes.Enum.NETWORK_UPDATE_SAGA, NETWORK_UPDATE_SAGA),
    takeEvery(NetworkTypes.Enum.CACHED_TOKENS_UPDATE_SAGA, CACHED_TOKENS_UPDATE_SAGA),
    takeEvery(NetworkTypes.Enum.EXCHANGE_RATES_UPDATE_SAGA, EXCHANGE_RATES_UPDATE_SAGA),
  ])
}
